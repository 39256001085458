<div *ngIf="isShow" (click)="close()" (contextmenu)="close()" class="back centerX">
  <div (click)="$event.stopPropagation()" class="popupBack"
    style="padding: 5px; margin-top: 100px;height: 500px;width: 60%;min-width: 500px;max-width: 1000px;border-radius: 20px;">
    <div class="contentView">
      <div class="divX centerY" style="height: 50px;">
        <div style="font-size: 20px;color: #2f3542;margin-left: 20px;margin-top: 10px;">
          <b>设置</b>
        </div>
        <div style="margin-left: 20px;justify-content:column-reverse;margin-top: 10px;" class="divX ">
          <div class="divY center menuItem">
            <div style="font-size: 12px;">
              快捷回复
            </div>
            <div class="menuItemLine"></div>
          </div>
          <div *ngIf='false' class="divY center menuItem">
            <div style="font-size: 12px;">
              主题
            </div>
            <div class="menuItemLine"></div>
          </div>
        </div>
      </div>
      <div style="height: 345px;width: 100%;margin-top: 20px;">
        <app-quickreply></app-quickreply>
      </div>
    </div>
    <div class="buttonView divX">
      <div (click)="close()" class="cancelButton center" style="width: 80px;height: 35px;margin-right: 10px;">
        返回
      </div>
      <div (click)="ok()" class="okButton center" style="width: 80px;height: 35px;margin-left: 10px;">
        完成
      </div>
    </div>
  </div>
</div>