import { Injectable } from '@angular/core';
import { element } from 'protractor';
import { Subject, Observer, Observable, Subscriber } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private ws: WebSocket;
  private isLogin = false;
  private url = '';
  private obList: SocketListenerModel[] = [];
  // private websocektUrl = 'ws://192.168.3.15:10003/webSocket/1/';
  private websocektUrl = 'wss://csapi.mish.top/webSocket/1/';
  keys = new SocketKey();

  constructor() { }
  // 返回一个可观测的流，包括服务器返回的消息
  connect(token: string) {
    this.url = `${this.websocektUrl}${token}`;
    this.ws = new WebSocket(this.url);
    const that = this;
    this.ws.onmessage = (res => {
      that.receiveMessage(res);
    });
    this.ws.onerror = (res => {
      this.socektError(res);
    });

  }

  // 关闭
  close() {
    this.ws.close();
  }

  // 接收服务器消息
  private receiveMessage(msg: MessageEvent) {
    const body = JSON.parse(msg.data) as SocketBodyModel;
    console.warn('receiveMessage');
    console.log(body);
    this.obList.forEach(item => {
      if (item.key === body.key) {
        item.observer.next(body);
      }
    });
  }

  // 连接错误，重连
  private socektError(msg: Event) {
    this.ws = null;
    const that = this;
    console.warn('socektError');
    console.log(msg);
    if (this.isLogin) {// 如果登录成功，可进行重连
      setTimeout(() => {
        that.connect(that.url);
      }, 1000 * 10);
    }
  }

  // 登录成功
  loginSuccess() {
    this.isLogin = true;
  }

  // 登录失败
  loginError() {
    this.isLogin = false;
  }

  // 向服务器端发送消息
  sendMessage(key: string, time: number, message: any): boolean {
    if (this.ws) {
      if (this.ws.readyState === 1) {
        const socketBodyModel = new SocketBodyModel();
        socketBodyModel.key = key;
        socketBodyModel.timestamp = time;
        socketBodyModel.data = message;
        console.warn('sendMessage');
        console.log(socketBodyModel);
        this.ws.send(JSON.stringify(socketBodyModel));
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // 向服务器端发送消息
  sendMessageNoData(key: string): boolean {
    if (this.ws) {
      if (this.ws.readyState === 1) {
        const socketBodyModel = new SocketBodyModel();
        socketBodyModel.key = key;
        socketBodyModel.timestamp = new Date().getTime();
        socketBodyModel.data = {};
        console.warn('sendMessage');
        console.log(socketBodyModel);
        this.ws.send(JSON.stringify(socketBodyModel));
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // 监听socket消息
  addSocketListener(key: string): Observable<SocketBodyModel> {
    return new Observable(observer => {
      this.obList.push({ key, observer });
    });
  }

  // 移除所有的监听
  removeAllListener() {
    this.obList = [];
  }
}

class SocketKey {
  Error = 'error'; // socket错误
  GetMyDetail = 'getMyDetail'; // 获取个人详情
  CreateChat = 'createChat'; // 与用户创建一个会话
  Read = 'read'; // 设置消息已读
  GetChatingList = 'getChatingList'; // 获取客服的所有会话
  GetMessageRec = 'getMessageRec'; // 根据对方的imId获取聊天记录
  CloseChat = 'closeChat'; // 客服发起服务评分
  Receive = 'receive'; // 接收到小学
  Send = 'send'; // 发送消息
  CustomerServerLogin = 'customerServerLogin'; // 登录回调
  GetChat = 'getChat'; // 更具chatid获取用户的信息
  GetMessageNoRead = 'getMessageNoRead'; // 获取未读消息
  Read2 = 'read2'; // 通过用户id 设置用户消息已读
}

class SocketListenerModel {
  key: string;
  observer: Subscriber<any>;
}

class SocketBodyModel {
  key = ''; // 消息的key
  timestamp = 0; // 消息时间戳
  code = 0; // 消息类型 0失败 1成功
  data: any; // 消息内容
  message = ''; // 服务器提示消息
}
