import { ClientUserModel } from './ClientUserModel';

export class ChatInfoModel {
    userId = 0; // 客服id
    clientId = 0; // 客户id
    chattingId = 0;
    listId = 0;
    inputText = ''; // 输入框里面的消息
    clientUser = new ClientUserModel(); // 客户信息;
    tagContent = ''; // 标记内容
}
