import { Component, Output, EventEmitter, Input, OnInit, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AudioService } from 'src/service/audio.service';
import { SimpleUser, SimpleUserOptions } from 'sip.js/lib/platform/web';
declare let moorCall;
declare let initM7WebPhone;
declare let md5: (text: string) => string;
declare let softphoneBar;
declare let window;
declare let phone;
declare let navigator;
@Component({
  selector: 'sip-view',
  templateUrl: './sipView.component.html',
  styleUrls: ['./sipView.component.css']
})
export class SipViewComponent implements AfterViewInit {
  isCalling = false;
  isIncomingCall = false;
  statusText = '呼叫中';
  callInterval: any = null;
  callTime = 0;
  networkMonitoring = 0;
  delay = 0;
  callPhone = '';
  constructor(private toast: ToastrService, private audioService: AudioService) {
  }

  ngAfterViewInit() {
    this.connectSip();
  }

  call() {
    if (!this.callPhone) {
      this.toast.warning('请输入手机号码');
      return;
    }
    this.statusText = '呼叫中';
    softphoneBar.dialout(this.callPhone, '');
    this.isCalling = true;
    this.callTime = 0;
    const that = this;
    this.callInterval = setInterval(() => {
      that.callTime += 1000;
    }, 1000);
  }

  hangup() {
    clearInterval(this.callInterval);
    this.callTime = 0;
    this.isCalling = false;
    this.isIncomingCall = false;
    this.audioService.stopIncomingCall();
    phone.hangup();
  }

  getAudioElement(id: string): HTMLAudioElement {
    const el = document.getElementById(id);
    if (!(el instanceof HTMLAudioElement)) {
      throw new Error(`Element "${id}" not found or not an audio element.`);
    }
    return el;
  }

  connectSip() {
    // SIP over WebSocket Server URL
    // The URL of a SIP over WebSocket server which will complete the call.
    // FreeSwitch is an example of a server which supports SIP over WebSocket.
    // SIP over WebSocket is an internet standard the details of which are
    // outside the scope of this documentation, but there are many resources
    // available. See: https://tools.ietf.org/html/rfc7118 for the specification.
    const server = "ws://192.168.3.5/ws";
    const a=new WebSocket('ws://192.168.198.131')
    return

    // SIP Request URI
    // The SIP Request URI of the destination. It's "Who you wanna call?"
    // SIP is an internet standard the details of which are outside the
    // scope of this documentation, but there are many resources available.
    // See: https://tools.ietf.org/html/rfc3261 for the specification.
    const destination = "sip:1003@192.168.3.5";

    // SIP Address of Record (AOR)
    // This is the user's SIP address. It's "Where people can reach you."
    // SIP is an internet standard the details of which are outside the
    // scope of this documentation, but there are many resources available.
    // See: https://tools.ietf.org/html/rfc3261 for the specification.
    const aor = "sip:1000@192.168.3.5";

    // SIP Authorization Username
    // This is the user's authorization username used for authorizing requests.
    // SIP is an internet standard the details of which are outside the
    // scope of this documentation, but there are many resources available.
    // See: https://tools.ietf.org/html/rfc3261 for the specification.
    const authorizationUsername = '1000';

    // SIP Authorization Password
    // This is the user's authorization password used for authorizing requests.
    // SIP is an internet standard the details of which are outside the
    // scope of this documentation, but there are many resources available.
    // See: https://tools.ietf.org/html/rfc3261 for the specification.
    const authorizationPassword = '123456';

    // Configuration Options
    // These are configuration options for the `SimpleUser` instance.
    // Here we are setting the HTML audio element we want to use to
    // play the audio received from the remote end of the call.
    // An audio element is needed to play the audio received from the
    // remote end of the call. Once the call is established, a `MediaStream`
    // is attached to the provided audio element's `src` attribute.
    const options: SimpleUserOptions = {
      aor,
      media: {
        remote: {
          audio: this.getAudioElement("remoteAudio")
        }
      },
      userAgentOptions: {
        authorizationPassword,
        authorizationUsername,
      }
    };

    // Construct a SimpleUser instance
    const simpleUser = new SimpleUser(server, options);

    // Supply delegate to handle inbound calls (optional)
    simpleUser.delegate = {
      onCallReceived: async () => {
        simpleUser.answer();
      }
    };

    // Connect to server
    simpleUser.connect();

    // // Register to receive inbound calls (optional)
    simpleUser.register();

    // const a = new WebSocket('ws://192.168.3.212:8088');

    // const loginData = {
    //   username: '8000@mxkj',
    //   pwd: md5('#VBrBNba4o8000')
    // };
    // const that = this;
    // moorCall.moortools.m7BeginLogon('8000@mxkj', '#VBrBNba4o8000', 'sip', '0');
    // window.webPhone = new initM7WebPhone(loginData);
    // window.webPhone.networkMonitoring(msg => {
    //   if (msg === 'error') {
    //     that.delay = 0;
    //     that.networkMonitoring = -1;
    //   } else {
    //     that.delay = parseInt(msg);
    //     if (msg < 50) {
    //       that.networkMonitoring = 4;
    //     } else if (msg >= 50 || msg < 100) {
    //       that.networkMonitoring = 3;
    //     } else if (msg >= 100 || msg < 250) {
    //       that.networkMonitoring = 2;
    //     } else if (msg >= 250) {
    //       that.networkMonitoring = 1;
    //     }
    //   }
    // });
    // let isDTMF = false;
    // window.webPhone.onMessage((msg, data) => {
    //   console.error(msg);
    //   if (msg === 'incomingcall' || msg === 'Outbound') {
    //     isDTMF = true;
    //   }
    //   if (msg === 'registered') {
    //     console.log('连接成功');
    //   } else if (msg === 'incomingcall') {
    //     if (that.isCalling) {
    //       window.webPhone.Outbound();
    //     } else {
    //       that.isIncomingCall = true;
    //       const reg = new RegExp('"', 'g');
    //       that.callPhone = data.result.displayname.replace(reg, '');
    //       that.audioService.playIncomingCall();
    //     }
    //   } else if (msg === 'hangup') {
    //     that.audioService.stopIncomingCall();
    //     that.statusText = '已挂断';
    //     setTimeout(() => {
    //       that.hangup();
    //     }, 1000);
    //   } else if (msg === 'kicked') {
    //     window.webPhone.destroy();
    //   }
    // });

    // window.webPhone.onError(error => {
    //   console.error(error);
    //   if (error && error.type !== 'socket') {
    //     console.log('janusError:', error);
    //   }
    //   if (error === 'JanusError: destroyed') {

    //   }
    // });
  }

  // 接听电话
  outbound() {
    window.webPhone.Outbound();
    this.isIncomingCall = false;
    this.audioService.stopIncomingCall();
    this.statusText = '通话中';
    this.isCalling = true;
    this.callTime = 0;
    const that = this;
    this.callInterval = setInterval(() => {
      that.callTime += 1000;
    }, 1000);
  }

}
