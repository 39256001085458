<div class="back divX center">
  <audio id="remoteAudio" controls>
    <p>Your browser doesn't support HTML5 audio.</p>
  </audio>
  <input [(ngModel)]="callPhone" *ngIf="true" [disabled]="isCalling||isIncomingCall"
    style="background: #0000;width: 100px;font-size: 14px;" placeholder="请输入呼叫号码">
  <div *ngIf="isCalling" style="font-size: 12px;margin-left: 10px;" class="divY center">
    <div style="color: #ff7f50;">
      {{statusText}}
    </div>
    <div style="color: #ff6348;font-weight: bold;">
      {{callTime | date:'mm:ss'}}
    </div>
  </div>
  <div *ngIf="isIncomingCall" style="font-size: 10px;color: #ffa502;margin-left: 10px;">
    来电呼叫
  </div>
  <img ngbTooltip='点击拨打电话' *ngIf="!isCalling&&!isIncomingCall" (click)="call()" src="../../assets//img/ic_拨号.svg"
    style="width: 25px;height:25px;margin-left: 20px;cursor: pointer;" />
  <img ngbTooltip='点击接听电话' class="incomingCallImg" *ngIf="isIncomingCall" (click)="outbound()"
    src="../../assets//img/ic_接听.svg" />
  <img ngbTooltip='点击挂断电话' *ngIf="isCalling||isIncomingCall" (click)="hangup()" src="../../assets//img/ic_挂断.svg"
    style="width: 25px;height:25px;margin-left: 20px;cursor: pointer;" />
  <img ngbTooltip='检测通话状态中' *ngIf="networkMonitoring==0" src="../../assets//img//ic_加载中.svg" class="loadNetWorkImg">
  <img ngbTooltip='无网络信号' *ngIf="networkMonitoring==-1" src="../../assets//img//ic_无网络.svg" width="25px"
    style="margin-left: 20px;">
  <div [ngbTooltip]="networkMonitoring==4?'极好':networkMonitoring==3?'良好':networkMonitoring==2?'一般':'差'"
    class="divY center" style="margin-left: 15px;">
    <div *ngIf="networkMonitoring>0" class="divX" style="align-items:flex-end;">
      <div style="width: 3px;height: 5px;"
        [ngStyle]="{background:networkMonitoring==4?'#74C637':networkMonitoring==3?'#eccc68':networkMonitoring==2?'#ffa502':'#ff6348'}">
      </div>
      <div style="width: 3px;height: 8px;margin-left: 1px;"
        [ngStyle]="{background:networkMonitoring==4?'#74C637':networkMonitoring==3?'#eccc68':networkMonitoring==2?'#ffa502':'#ddd'}">
      </div>
      <div style="width: 3px;height: 11px;margin-left: 1px;"
        [ngStyle]="{background:networkMonitoring==4?'#74C637':networkMonitoring==3?'#eccc68':'#ddd'}">
      </div>
      <div style="width: 3px;height: 14px;margin-left: 1px;"
        [ngStyle]="{background:networkMonitoring==4?'#74C637':'#ddd'}">
      </div>
    </div>
    <div *ngIf="networkMonitoring>0" style="font-size: 12px;transform:scale(0.8);">
      {{delay}}ms
    </div>
  </div>
</div>