import { Injectable } from '@angular/core';
import { Subject, Observer, Observable, Subscriber } from 'rxjs';
import { AcceleratorsModel } from 'src/model/AcceleratorsModel';
@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private mp3 = [
    '../assets/mp3/7824.mp3',
    '../assets/mp3/8858.mp3',
    '../assets/mp3/11611.mp3',
    '../assets/mp3/6124.mp3',
  ];
  private playMp3Time = 0; // 播放音效时间，防止轰炸
  private audio: any = null; // 语音播放对象
  private isPalyCallAudio = false;

  // 播放消息提示音
  public playMessageAudio() {
    if (this.isPalyCallAudio) {
      return;
    }
    if (new Date().getTime() - this.playMp3Time > 1000 * 2) {
      this.playMp3Time = new Date().getTime();
      try {
        this.audio = new Audio(this.mp3[Math.floor(Math.random() * 4)]); // 这里的路径写上mp3文件在项目中的绝对路径
        this.audio.play(); // 播放
      } catch {
      }
    }
  }

  // 播放来电提示音
  public playIncomingCall() {
    this.isPalyCallAudio = true;
    if (this.audio) {
      this.audio.pause();
    }
    const that = this;
    this.audio = new Audio('../assets/mp3/incomingCall.mp3'); // 这里的路径写上mp3文件在项目中的绝对路径
    this.audio.addEventListener('ended', () => {
      if (that.isPalyCallAudio) {
        this.audio.play();
      }
    }, false);
    this.audio.play(); // 播放
  }

  // 停止播放来电提示音
  public stopIncomingCall() {
    this.isPalyCallAudio = false;
    if (this.audio) {
      this.audio.pause();
    }
    this.audio = null;
  }
}
