import { SqliteBase } from 'src/base/SqliteBase';
import { ClientUserModel } from './ClientUserModel';

@SqliteBase.DataBase('ChatListModel')
export class ChatListModel extends SqliteBase {
    @SqliteBase.Parameter('INTEGER')
    @SqliteBase.PrimaryKey()
    @SqliteBase.AutoIncrement()
    @SqliteBase.NotNull()
    @SqliteBase.Unique()
    id = 0; // 聊天id
    @SqliteBase.Parameter('INTEGER')
    userId = 0; // 客服id
    @SqliteBase.Parameter('INTEGER')
    clientId = 0; // 用户id
    @SqliteBase.Parameter('INTEGER')
    lastMessageTime = 0; // 聊天时间
    @SqliteBase.Parameter('VARCHAR(255)')
    lastMessage = ''; // 最后一条消息
    @SqliteBase.Parameter('INTEGER')
    lastMessageType = 0; // 最后一条消息类型
    @SqliteBase.Parameter('INTEGER')
    unReadNumber = 0; // 未读数量
    client = new ClientUserModel();
    @SqliteBase.Parameter('BLOB')
    isTag = false; // 是否标记
    @SqliteBase.Parameter('VARCHAR(255)')
    tagContent = ''; // 标记内容
    @SqliteBase.Parameter('VARCHAR(255)')
    unSendText = ''; // 未发送消息
    @SqliteBase.Parameter('BLOB')
    isVisible = false; // 是否隐藏
    @SqliteBase.Parameter('INTEGER')
    chatId = 0; // 会话id;
}
