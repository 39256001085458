<div *ngIf="isShow" class="back center">
  <!-- <sip-view></sip-view> -->
  <div class="loginTopText">
    觅生活
  </div>
  <div class="loginBack">
    <div class="loginText divX">
      客服登录
      <img src="../assets//img//ic_客服.svg" style="position: absolute;width: 16px;right: 40px;margin-top:3px">
    </div>
    <div style="margin-top: 30px;" class="divX centerY">
      <div class="loginText2">账号：</div>
      <input [(ngModel)]='account' class="loginInput" placeholder="请输入账号">
    </div>
    <div style="margin-top: 20px;" class="divX centerY">
      <div class="loginText2">密码：</div>
      <input [(ngModel)]='password' class="loginInput" [type]="isHidePassword?'password':'text'" placeholder="请输入密码">
      <img (click)="isHidePassword=!isHidePassword"
        [src]="isHidePassword?'../assets/img/ic_隐藏密码.svg':'../assets/img/ic_查看密码.svg'"
        style="position: relative;left: -30px;cursor: pointer;">
    </div>
    <div (click)="login()" class="loginButton center">
      登录
    </div>
  </div>
  <div class="centerX loginButtonText">
    云南觅享科技有限公司 | © 2020 - 2020 Mish Inc. All Rights Reserved
  </div>
</div>