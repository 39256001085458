export class AcceleratorsModel {
    hasAlt = false;
    public hasControl = false;
    public hasShift = false;
    public otherKey = '';

    equals(model: AcceleratorsModel): boolean {
        return model.hasAlt === this.hasAlt && model.hasControl === this.hasControl
            && model.hasShift === this.hasShift && model.otherKey.toLowerCase() === this.otherKey.toLowerCase();
    }

    toString(): string {
        return `${this.hasAlt ? 'alt+' : ''} ${this.hasControl ? 'ctrl+' : ''} ${this.hasShift ? 'shift+' : ''} ${this.otherKey}`;
    }
}