<div *ngIf="isShow" (click)="close()" (contextmenu)="close()" class="back centerX">
  <div (click)="$event.stopPropagation()" class="popupBack"
    style="padding: 5px; margin-top: 100px;height: 210px;width: 500px;border-radius: 20px;">
    <textarea  [(ngModel)]="tagText"  placeholder="请输入标记内容" class="inputView"></textarea>
    <div class="buttonView divX">
      <div (click)="close()" class="cancelButton center" style="width: 80px;height: 35px;margin-right: 10px;">
        取消
      </div>
      <div (click)="ok()" class="okButton center" style="width: 80px;height: 35px;margin-left: 10px;">
        标记
      </div>
    </div>
  </div>
</div>