import { Observable } from 'rxjs';
import { SqliteBase } from 'src/base/SqliteBase';
import { ISqliteSelectController } from './ISqliteSelectController';
import { SqliteControllerListener } from './SqliteControllerListener';
import { Util } from './Util';

export class SqliteController<T extends SqliteBase> implements ISqliteSelectController<T> {
    private whereStr: string = null;
    private limitNum: number = null;
    private offsetNum: number = null;
    private pageNum: number = null;
    private orderByStr: string = null;
    private descORascStr: string = null;
    private joinList: string[] = [];

    constructor(private sqliteControllerListener: SqliteControllerListener<T>) {
    }

    // 查询条件
    public where(keys: string | null, values: any[]): SqliteController<T> {
        if (keys !== null) {
            this.whereStr = Util.stringFormat(keys, values);
        } else {
            this.whereStr = '';
        }

        return this;
    }

    // 关联查询
    public join(join: string): ISqliteSelectController<T> {
        this.joinList.push(join);
        return this;
    }

    // 查询数量
    public limit(limit: number): ISqliteSelectController<T> {
        this.limitNum = limit;
        return this;
    }

    // 查询开始位置
    public offset(offset: number): ISqliteSelectController<T> {
        this.offsetNum = offset;
        return this;
    }

    // 分页页数
    public page(page: number): ISqliteSelectController<T> {
        this.pageNum = page;
        return this;
    }

    // 根据需求排序
    public orderBy(orderBy: string): ISqliteSelectController<T> {
        this.orderByStr = orderBy;
        return this;
    }

    // 降序
    public desc(): ISqliteSelectController<T> {
        this.descORascStr = 'desc';
        return this;
    }

    // 升序
    public asc(): ISqliteSelectController<T> {
        this.descORascStr = 'asc';
        return this;
    }

    // 开始查找
    public find(model: T): Observable<T[]> {
        return this.sqliteControllerListener.execute(`select * from ${model.constructor.name} ${this.splicingSql(true)}`, model);
    }

    // 开始join查找
    public findJoin(tableName: string, model: T): Observable<T[]> {
        return this.sqliteControllerListener.execute(`select * from ${tableName} ${this.splicingSql(true)}`, model);
    }

    // 不根据实体类进行查找数据
    public findAny(tableName: string): Observable<any[]> {
        return this.sqliteControllerListener.execute(`select * from ${tableName} ${this.splicingSql(true)}`, null);
    }

    // 开始删除
    public delete(model: T): Observable<T[]> {
        return this.sqliteControllerListener.execute(`delete from ${model.constructor.name} ${this.splicingSql(false)}`, model);
    }

    // 开始更新
    public update(model: T, keys: string, ...values: any[]): Observable<T[]> {
        return this.sqliteControllerListener.execute(`update ${model.constructor.name} set ${Util.stringFormat(keys, values)} ${this.splicingSql(false)}`, model);
    }

    // 拼接sql
    splicingSql(isFind: boolean): string {
        let sql = ``;
        this.joinList.forEach(join => {
            sql += join + ' ';
        });
        if (this.whereStr !== null) {
            sql += ` where ${this.whereStr}`;
        }

        if (this.orderByStr !== null) {
            if (isFind) {
                sql += ` order by ${this.orderByStr}`;
            } else {
                console.warn('order by 在非find方法里无效！');
            }
        }
        if (this.descORascStr !== null) {
            if (isFind) {
                sql += ` ${this.descORascStr}`;
            } else {
                console.warn('desc or asc by 在非find方法里无效！');
            }
        }

        if (this.limitNum !== null) {
            if (isFind) {
                sql += ` limit ${this.limitNum}`;
            } else {
                console.warn('limit 在非find方法里无效！');
            }
        }
        if (this.offsetNum !== null) {
            if (this.limitNum === null) {
                console.warn('offset 必须配合limit使用！');
            } else {
                if (isFind) {
                    sql += ` offset ${this.offsetNum}`;
                } else {
                    console.warn('offset 在非find方法里无效！');
                }
            }
        } else if (this.pageNum !== null) {
            if (this.limitNum === null) {
                console.warn('page 必须配合limit使用！');
            } else {
                if (isFind) {
                    sql += ` offset ${this.limitNum * this.pageNum}`;
                } else {
                    console.warn('offset 在非find方法里无效！');
                }
            }
        }


        return sql;
    }

}
