import { Component, Output, EventEmitter, Input, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PopupBaseComponent } from '../popupBase/popupBase.component';
@Component({
  selector: 'popup-inputTag',
  templateUrl: './popupInputTag.component.html',
  styleUrls: ['./popupInputTag.component.css']
})
export class PopupInputTagComponent implements AfterViewInit {
  @Output() inputChange = new EventEmitter();

  tagText = '';
  isShow = false;
  constructor(private toast: ToastrService) {
  }

  ok() {
    if (this.tagText.length === 0) {
      this.toast.warning('输入点标记内容吧！');
      return;
    }
    if (this.inputChange) {
      this.inputChange.emit(this.tagText);
    }
    this.tagText = '';
    this.isShow = false;
  }

  ngAfterViewInit() {
  }

  show() {
    this.isShow = true;
  }

  close() {
    this.isShow = false;
  }

}
