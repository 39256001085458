import { SqliteBase } from 'src/base/SqliteBase';

export class ClientUserModel extends SqliteBase {
    @SqliteBase.Parameter('INTEGER')
    @SqliteBase.PrimaryKey()
    @SqliteBase.AutoIncrement()
    @SqliteBase.NotNull()
    @SqliteBase.Unique()
    id = 0;
    @SqliteBase.Parameter('INTEGER')
    clientId = 0;
    @SqliteBase.Parameter('VARCHAR(20)')
    name = '';
    @SqliteBase.Parameter('VARCHAR(4)')
    gender = '';
    @SqliteBase.Parameter('VARCHAR(20)')
    account = '';
    @SqliteBase.Parameter('VARCHAR(255)')
    avatar = '';
    @SqliteBase.Parameter('INTEGER')
    lastUpdateTime = 0;
}
