<div *ngIf="searchText" class="back">
    <div style="width: 100%;height: 100%;" class='divY'>
        <div style="font-size:12px;margin-left: 10px;color: #999;">
            用户搜索结果
            <span class="color:black">
                {{clientList.length}}
            </span>
        </div>
        <div class="scroll" style="margin: 5px 10px;font-size: 12px;max-height: 300px;">
            <div class="center" style="width: 100%;margin-top: 20px;margin-bottom: 20px;" *ngIf="clientList.length==0">
                暂无搜索结果
            </div>
            <div (click)="select(item.clientId)" *ngFor="let item of clientList" class="divX centerY select">
                <img [src]="item.avatar" width="30px" height="30px" style="border-radius: 90px;">
                <div class="divY" style="margin-left:10px">
                    <div>
                        {{item.name}}
                    </div>
                    <div style="color:#999">
                        {{item.account}}
                    </div>
                </div>
            </div>
        </div>
        <div style="font-size:12px;margin-left: 15px;color: #999;">
            聊天搜索结果
            <span class="color:black">
                {{messageList.length}}
            </span>
        </div>
        <div class="scroll" style="margin: 5px 10px;font-size: 12px;max-height: 400px;">
            <div class="center" style="width: 100%;margin-top: 20px;margin-bottom: 20px;" *ngIf="messageList.length==0">
                暂无搜索结果
            </div>
            <div (click)="select(item.clientId)" *ngFor="let item of messageList" class="divY select">
                <div style="width: 100%;height: 1px;background: #eee;margin-bottom: 5px;">
                </div>
                <div>
                    <span>
                        {{item.name+'('+item.account+')'}}
                    </span>
                    <span style="color:#ff7f50;">
                        [{{item.type==1?'文字':item.type==2?'图片':item.type==6?'评分':'其他'}}]
                    </span>
                    <span style="color: #999;">
                        {{item.sendTime | dateTime}}
                    </span>
                </div>
                <div style="background: #f0f0f0;padding: 5px 0px;margin-top: 5px;">
                    <span *ngIf="item.userType==1" style="color:#ff7f50 ;">
                        我:
                    </span>
                    {{item.message}}
                </div>

            </div>
        </div>
    </div>
</div>