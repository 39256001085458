<div class="mainView divX" (dragover)='$event.preventDefault()' (drop)='drop($event)' (paste)='paste($event)'
    (mouseup)="isCanMoveBottomView=false" (mousemove)="changeBottomViewSize($event)"
    [ngStyle]="{cursor:isCanMoveBottomView?'ns-resize':'default'}">
    <div style="width: 100%;height: 100%;user-select: none;" class="center divY" *ngIf="!chattingInfoModel">
        <img src="../../assets//img//ic_没有聊天信息.svg" style="opacity:0.5">
        <div style="margin-top: 10px;font-size: 14px;">
            积极处理用户问题，当一个好客服！
        </div>
        <div style="margin-top: 10px;font-size: 12px;color: #999;">
            遇事冷静，保留每天好心情！
        </div>
    </div>
    <div style="width: 100%;height: 100%;" *ngIf="chattingInfoModel">
        <div class="titleView centerY divX">
            <img (click)="backChat()" src="../../assets//img/ic_返回.svg" width="20px"
                style="cursor: pointer;margin-left: 20px;">
            <img [src]="chattingInfoModel.clientUser.avatar" class="clientImg">
            <div style="margin-left: 10px;font-size: 16px;">
                <div>
                    {{chattingInfoModel.clientUser.name}}
                </div>
                <div style="font-size: 12px;color: #aaa;">
                    {{chattingInfoModel.clientUser.account}}
                </div>
            </div>
            <div (dblclick)='overChat()' *ngIf="chattingInfoModel.chattingId" ngbTooltip="结束本次服务并评分"
                class="divX centerY overChat">
                <div>
                    结束本次服务{{chattingInfoModel.chattingId}}
                </div>
                <img src="../../assets//img//ic_结束.svg" style="margin-left: 5px;">
            </div>
            <div class="tagView" *ngIf="isWindow">
                {{chattingInfoModel.tagContent}}
            </div>
        </div>
        <div #contentView class="messageContentView divY" (scroll)="scroll($event,contentView)"
            [ngStyle]="{height:messageContentViewHeight+'px'}">
            <div style="width: 100%;height: 100%;user-select: none;" class="center divY" *ngIf="messageList.length==0">
                <img src="../../assets//img//ic_没有聊天信息.svg" style="opacity:0.5">
                <div style="margin-top: 10px;font-size: 12px;">
                    你跟用户暂无互动信息哦
                </div>
            </div>
            <div *ngFor="let item of messageList;let i =index" [ngStyle]="{padding:isWindow?'10px 40px':'10px 10px'}">
                <div class="center divX"
                    *ngIf="i==messageList.length-1||item.sendTime-messageList[i+1].sendTime>1000*60*5">
                    <div class="messageTime center">
                        {{item.sendTime | dateTime}}
                    </div>
                </div>
                <div *ngIf="item.userType==1" class="userMessageView divX">
                    <div class="center messageChatTime" *ngIf="isSHowChatMessageTime">
                        {{item.sendTime | dateTime}}
                    </div>
                    <img *ngIf="!item.sendSucces" ngbTooltip="发送失败，点击重新发送！" src="../../assets//img//ic_失败.svg"
                        style="margin-right: 10px;cursor: pointer;">
                    <div *ngIf="item.type==1" class="messageText">
                        {{item.message}}
                    </div>
                    <div (click)="showImg(item)" *ngIf="item.type==2" class="messageImg">
                        <img [src]="item.message" class="messageImgShow">
                        <div *ngIf="!item.isUpload" class="imgUploadProgrees center"
                            [ngStyle]="{height:100-item.fileUploadProgress+'%'}">
                        </div>
                    </div>
                    <div *ngIf="item.type==5" class="messageText">
                        会话（<span style="color:#2f3542;">{{item.message}}</span>）已结束，等待用户评分

                    </div>

                    <div style="position: relative;left: -10px;top:6px">
                        <img src="../../assets//img/ic_右.svg" width="25px">
                    </div>
                    <img [src]="userDetail?(userDetail.avatar?userDetail.avatar:'../../assets/img/ic_客服头像.svg'):'../../assets/img/ic_客服头像.svg'"
                        class="messageUserImg">
                </div>

                <div *ngIf="item.userType==2" class="divX">
                    <img [src]="chattingInfoModel.clientUser.avatar" class="messageUserImg">
                    <div style="position: relative;right:-4px;top:8px">
                        <img src="../../assets//img/ic_左.svg" width="20px">
                    </div>
                    <div *ngIf="item.type==1" class="messageTextClient">
                        {{item.message}}
                    </div>
                    <div *ngIf="item.type==6" class="messageTextClient divY">
                        <div>
                            用户已完成评分：
                        </div>
                        <div class="divX centerY" style="margin-top: 5px;">
                            <span *ngFor="let gende of (item.message|string2numberArray)">
                                🌹
                            </span>
                            <div style="margin-left: 5px;font-size: 10px;">
                                <b>
                                    {{item.message}}分
                                </b>
                            </div>
                        </div>
                    </div>
                    <div (click)="showImg(item)" *ngIf="item.type==2" class="messageImageClient">
                        <img [src]="item.message" class="messageImgShow">
                        <div *ngIf="!item.isUpload" class="imgUploadProgrees center"
                            [ngStyle]="{height:100-item.fileUploadProgress+'%'}"></div>
                    </div>
                    <div class="center messageChatTime" *ngIf="isSHowChatMessageTime">
                        {{item.sendTime | dateTime}}
                    </div>
                </div>
            </div>
            <div class="pastePathImg divY" *ngIf="pastePath">
                <div style="position: relative;">
                    点击发送图片
                    <img (click)='pastePath = null' ngbTooltip='取消' src="../../assets//img//ic_关闭.svg" width="12px"
                        style="position: absolute;right:5px;cursor: pointer;">
                </div>
                <img ngbTooltip='点击发送' (click)="sendPasteImg()" [src]="pastePath" class="messageImgShow"
                    style="margin-top: 10px;">
            </div>
        </div>
        <div class="bottomChangeSizeView" (mousedown)="mouseDown($event)"></div>
        <div class="bottomView" [ngStyle]="{height:bottomViewHeight+'px'}">
            <div *ngIf='isOpenQuickReplay' class="quickReply centerY">
                <div class="quickReplyList divX2">
                    <div (dblclick)="sendQuickMessage(item)" class="quickReplyItem center"
                        *ngFor="let item of quickReplyList">
                        {{item}}
                    </div>
                </div>
            </div>
            <textarea placeholder="点击输入消息" [(ngModel)]="chattingInfoModel.inputText"
                (ngModelChange)="inputChange($event)" class="messageInput"
                [ngStyle]="{height:(bottomViewHeight-(isOpenQuickReplay?150:60))+'px'}">
            </textarea>
            <div class="centerY" style="justify-content:flex-end;">
                <div ngbTooltip='服务器历史记录' class="selectEmoji" (click)='showChatHistory(popupHistory)'>
                    <img (mouseout)="isMoveLsltjl=false" (mouseover)="isMoveLsltjl=true"
                        [src]="isMoveLsltjl?'../../assets//img/ic_历史记录2.svg':'../../assets//img//ic_历史记录.svg'"
                        width="24px" />
                </div>
                <div ngbTooltip='表情' class="selectEmoji" (click)='showSelectEmoji(popupBaseEmoji,popupEmoji,$event)'>
                    <img (mouseout)="isMoveEmoji=false" (mouseover)="isMoveEmoji=true"
                        [src]="isMoveEmoji?'../../assets//img//ic_emoji2.svg':'../../assets//img//ic_emoji.svg'"
                        width="24px" />
                </div>
                <div class="selectImg">
                    <input type="file" id="upload_file" accept=".png,.jpg,.jpeg" #upload_file style="display: none;"
                        (change)="fileChange($event);">
                    <img (mouseout)="isMoveImg=false" (mouseover)="isMoveImg=true"
                        [src]="isMoveImg?'../../assets//img//ic_图片2.svg':'../../assets//img//ic_图片.svg'" width="26px"
                        (click)="upload_file.click();" />
                </div>
                <div class="useQuickReply" (click)="openQuickReply()">
                    {{isOpenQuickReplay?'关闭快捷回复':'打开快捷回复'}}
                </div>
                <div (click)="sendCheckMessage()" class="sendButton center">
                    发送
                </div>
            </div>

        </div>

    </div>
</div>
<popup-base #popupBaseEmoji [isTransparent]='true'>
    <popup-emoji #popupEmoji (selectChange)="selectEmoji($event)"></popup-emoji>
</popup-base>
<popup-history #popupHistory>

</popup-history>