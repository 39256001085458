import { Injectable } from '@angular/core';
import { Subject, Observer, Observable, Subscriber } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ChatManagerService {
  obList: ChatManagerModel[] = [];
  public tag = new ChatManageTag();


  constructor() {
  }

  addListener(tag: number): Observable<any> {
    return new Observable(observer => {
      this.obList.push({ tag, observer });
    });
  }

  send(tag: number, msg) {
    this.obList.forEach(element => {
      if (element.tag === tag) {
        element.observer.next(msg);
      }
    });
  }


}

class ChatManagerModel {
  tag: number;
  observer: Subscriber<any>;
}

class ChatManageTag {
  public setChangeChat = 1; // 设置聊天
  public setChatInputText = 2; // 设置聊天输入的信息
  public removeChatting = 3; // 移除正在聊天的用户
  public backChating = 4; // 退出正在聊天的页面
  public changeChatList = 5; // 更改聊天列表
  public showHideChatMessageTime = 6; // 是否显示聊天时间
}
