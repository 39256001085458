import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebsocketService } from 'src/service/websocket.service';
import { fromEvent, Observable } from 'rxjs';
import { ChatManagerService } from 'src/service/chatManager.service';
import { ChatListModel } from 'src/model/ChatListModel';
import { ChatInfoModel } from 'src/model/ChatInfoModel';
import { AcceleratorsService } from 'src/service/accelerators.service';
import { AcceleratorsModel } from 'src/model/AcceleratorsModel';
import { MessageModel } from 'src/model/MessageModel';
import { DomSanitizer } from '@angular/platform-browser';
import { Util } from 'src/utils/Util';
import { SqlliteManager } from 'src/utils/SqlliteManager';
import * as qiniu from 'qiniu-js'
import HttpService from 'src/service/HttpService';
declare let window;

@Component({
  selector: 'chat-content',
  templateUrl: './contentView.component.html',
  styleUrls: ['./contentView.component.css']
})
export class ContentViewComponent implements OnInit {
  @ViewChild('filesMulti') private fileMulti: ElementRef;
  @ViewChild('contentView') private contentView: ElementRef;
  chattingInfoModel: ChatInfoModel; // 正在聊天的用户信息
  messageContentViewHeight = 0; // 消息列表组件高度
  bottomViewHeight = 150; // 底部组件高度
  downY = 0;
  isCanMoveBottomView = false;
  messageList: MessageModel[] = []; // 消息列表
  quickReplyList = [];
  isOpenQuickReplay = false;
  chatListModel: ChatListModel;
  selectImgPath;
  pastePath = null;
  pasteFile: File;
  isMoveEmoji = false;
  isMoveImg = false;
  isMoveLsltjl = false;
  isWindow = Util.isWindow();
  messageLi = 30;
  messagePi = 0;
  userDetail: any = null;
  isSHowChatMessageTime = false;
  constructor(private toast: ToastrService, private chatManager: ChatManagerService,
    private sanitizer: DomSanitizer, accelerators: AcceleratorsService, private socketService: WebsocketService,
    private changeDetectorRef: ChangeDetectorRef, private http: HttpService) {
    this.chatManager.addListener(this.chatManager.tag.setChangeChat).subscribe(res => {
      this.setChangeChat(res);
    });
    this.chatManager.addListener(this.chatManager.tag.removeChatting).subscribe(res => {
      this.chattingInfoModel = null;
    });

    this.chatManager.addListener(this.chatManager.tag.showHideChatMessageTime).subscribe(res => {
      this.isSHowChatMessageTime = res;
    });

    socketService.addSocketListener(socketService.keys.GetMyDetail).subscribe(data => {
      if (data.code === 1) {
        this.userDetail = data.data;
      }
    });

    socketService.addSocketListener(socketService.keys.Receive).subscribe(data => {
      if (data.code === 1) {
        const messageModel = new MessageModel();
        messageModel.isRead = false;
        messageModel.message = data.data.message;
        messageModel.sendTime = data.data.createTime;
        messageModel.type = data.data.messageType;
        messageModel.serverId = data.data.id;
        messageModel.userType = 2;
        messageModel.sendSucces = true;
        messageModel.userId = data.data.toImId;
        messageModel.clientId = data.data.fromImId;
        messageModel.isUpload = true;
        if (this.chatListModel && this.chattingInfoModel) {
          if (this.chatListModel.chatId === data.data.chatId) {
            messageModel.isRead = true;
            if (messageModel.type !== 6) {
              this.chattingInfoModel.chattingId = data.data.chatId;
            }
            this.socketService.sendMessage(this.socketService.keys.Read2
              , new Date().getTime()
              , { toImId: this.chattingInfoModel.clientId });
            this.messageList.unshift(messageModel);
            this.changeDetectorRef.detectChanges();
          }
        }
        messageModel.saveNoSub();
        this.contentMoveBottom();
      }
    });

    socketService.addSocketListener(this.socketService.keys.Send).subscribe(res => {
      if (res.code === 0) {
        this.sendMessageError(res.data);
      }
    });

    socketService.addSocketListener(socketService.keys.CloseChat).subscribe(data => {
      if (data.code === 1) {
        const messageModel = new MessageModel();
        messageModel.isRead = false;
        messageModel.message = data.data.chatId;
        messageModel.sendTime = data.data.createTime;
        messageModel.type = data.data.messageType;
        messageModel.userType = data.data.sendImType;
        messageModel.sendSucces = true;
        messageModel.serverId = data.data.id;
        messageModel.userId = data.data.fromImId;
        messageModel.clientId = data.data.toImId;
        messageModel.isUpload = true;
        if (this.chattingInfoModel) {
          if (this.chattingInfoModel.chattingId === data.data.chatId) {
            messageModel.isRead = true;
            this.chattingInfoModel.chattingId = 0;
            this.messageList.unshift(messageModel);
          }
        }
        messageModel.saveNoSub();
      }
    });

    let model = new AcceleratorsModel();
    model.otherKey = 'enter';
    model.hasShift = false;
    model.hasControl = true;
    model.hasAlt = false;
    accelerators.addListener(model).subscribe(res => {
      this.sendCheckMessage();
    });

    model = new AcceleratorsModel();
    model.otherKey = 'enter';
    model.hasShift = true;
    model.hasControl = false;
    model.hasAlt = false;
    accelerators.addListener(model).subscribe(res => {
      this.sendCheckMessage();
    });

    model = new AcceleratorsModel();
    model.otherKey = 'enter';
    model.hasShift = false;
    model.hasControl = false;
    model.hasAlt = true;
    accelerators.addListener(model).subscribe(res => {
      this.sendCheckMessage();
    });

    model = new AcceleratorsModel();
    model.otherKey = 'q';
    model.hasShift = false;
    model.hasControl = true;
    model.hasAlt = false;
    accelerators.addListener(model).subscribe(res => {
      this.openQuickReply();
    });

    model = new AcceleratorsModel();
    model.otherKey = 'c';
    model.hasShift = false;
    model.hasControl = false;
    model.hasAlt = true;
    accelerators.addListener(model).subscribe(res => {
      this.backChat();
    });
  }

  showChatHistory(popupHistory) {
    popupHistory.show({ userId: this.userDetail.id, clientId: this.chattingInfoModel.clientId });
  }

  showSelectEmoji(popupBaseEmoji, popupEmoji, event) {
    popupBaseEmoji.show(event.x - 200, event.y - 350);
    popupEmoji.show();
    this.changeDetectorRef.detectChanges();
  }


  scroll(event, contentView) {
    if (contentView.scrollTop === 0) {
      this.loadMessage();
    }
  }

  overChat() {
    this.socketService.sendMessage(this.socketService.keys.CloseChat, new Date().getTime(), { chatId: this.chattingInfoModel.chattingId });
  }

  sendMessageError(res: any) {
    this.messageList.forEach(item => {
      if (item.sendTime === res.clientTime) {
        item.sendSucces = false;
        item.updateNoSub();
      }
    });
  }

  drop(event) {
    event.preventDefault();
    if (!this.chattingInfoModel) {
      return;
    }
    const file = event.dataTransfer.files[0];
    if (!file) {
      return;
    }
    console.log(file);
    if (file.type.indexOf('image') === -1) {
      this.toast.warning('只能发送图片文件哦！');
      return;
    }
    this.pastePath = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    this.pasteFile = file;
  }

  paste(event) {
    if (!(event.clipboardData && event.clipboardData.items)) {
      return;
    }
    for (let i = 0, len = event.clipboardData.items.length; i < len; i++) {
      const item = event.clipboardData.items[i];
      console.log(item.kind);
      if (item.kind === 'file') {
        this.pastePath = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(item.getAsFile()));
        this.pasteFile = item.getAsFile();
      }
    }
  }

  sendPasteImg() {
    if (!this.pastePath) {
      this.toast.warning('发送图片失败，请重试');
      return;
    }
    this.sendImgMessage(this.pasteFile, this.pastePath);
    this.pastePath = null;
  }

  selectEmoji(event) {
    this.chattingInfoModel.inputText += event;
    this.inputChange(this.chattingInfoModel.inputText);
  }

  showImg(item) {
    if (item.isUpload) {
      window.open(item.message, '_blank');
    } else {
      window.open(item.message.changingThisBreaksApplicationSecurity, '_blank');
    }
  }

  fileChange(event) {
    const file = event.currentTarget.files[0];
    this.selectImgPath = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    this.sendImgMessage(file, this.selectImgPath);
    this.selectImgPath = '';
  }

  sendCheckMessage() {
    if (!this.chattingInfoModel) {
      return;
    }
    if (this.chattingInfoModel.inputText.length === 0) {
      return;
    }
    this.sendMessage(this.chattingInfoModel.inputText);
    this.chattingInfoModel.inputText = '';
    this.chatManager.send(this.chatManager.tag.setChatInputText, { id: this.chattingInfoModel.listId, text: '' });
  }

  sendMessage(msg) {
    if (!this.chattingInfoModel.chattingId) {
      this.toast.warning('请先创建一个新的会话才能发起聊天！');
      return;
    }
    const messageModel = new MessageModel();
    messageModel.id = 1;
    messageModel.isRead = true;
    messageModel.message = msg;
    messageModel.sendTime = new Date().getTime();
    messageModel.type = 1;
    messageModel.userType = 1;
    messageModel.sendSucces = true;
    messageModel.userId = this.chattingInfoModel.userId;
    messageModel.clientId = this.chattingInfoModel.clientId;
    messageModel.saveNoSub();
    this.socketService.sendMessage(this.socketService.keys.Send, messageModel.sendTime, {
      chatId: this.chattingInfoModel.chattingId,
      message: msg,
      messageType: 1
    });
    this.chatListModel.lastMessageType = 1;
    this.chatListModel.lastMessage = msg;
    this.chatManager.send(this.chatManager.tag.changeChatList, this.chatListModel);
    this.messageList.unshift(messageModel);
    this.contentMoveBottom();
  }

  sendImgMessage(file: File, path) {
    if (!this.chattingInfoModel.chattingId) {
      this.toast.warning('请先创建一个新的会话才能发起聊天！');
      return;
    }

    const messageModel = new MessageModel();
    messageModel.id = 1;
    messageModel.isRead = true;
    messageModel.message = path;
    messageModel.sendTime = new Date().getTime();
    messageModel.type = 2;
    messageModel.userType = 1;
    messageModel.sendSucces = true;
    messageModel.userId = this.chattingInfoModel.userId;
    messageModel.clientId = this.chattingInfoModel.clientId;
    this.messageList.unshift(messageModel);
    this.contentMoveBottom();
    this.chatListModel.lastMessageType = 2;
    this.chatListModel.lastMessage = '';
    this.chatManager.send(this.chatManager.tag.changeChatList, this.chatListModel);
    const options = {
      quality: 0.92,
      noCompressIfLarger: true
    };
    const that = this;
    this.http.get('/upload/getQNTokenIm', { suffix: 'png' }, result => {
      qiniu.compressImage(file, options).then((data: any) => {
        const observable = qiniu.upload(data.dist, result.data.key, result.data.token, {
          mimeType: 'image/png',
        }, {
          useCdnDomain: true,
          region: qiniu.region.z2
        });
        const observer = {
          next(res) {
            messageModel.fileUploadProgress = res.total.percent;
          },
          complete() {
            messageModel.isUpload = true;
            messageModel.sendTime = new Date().getTime();
            messageModel.message = `${result.data.prefix}/${result.data.key}`;
            messageModel.saveNoSub();
            that.socketService.sendMessage(that.socketService.keys.Send, messageModel.sendTime, {
              chatId: that.chattingInfoModel.chattingId,
              message: messageModel.message,
              messageType: 2
            });
          }
        };
        observable.subscribe(observer);
      });
    });

  }

  backChat() {
    if (!this.chattingInfoModel) {
      return;
    }
    this.chattingInfoModel = null;
    this.chatListModel = null;
    this.chatManager.send(this.chatManager.tag.backChating, null);
  }

  openQuickReply() {
    if (!this.chattingInfoModel) {
      return;
    }
    const list = JSON.parse(localStorage.getItem('quickReply'));
    this.quickReplyList = list ? list : [];
    if (!this.isOpenQuickReplay) {
      if (this.bottomViewHeight < 250) {
        this.bottomViewHeight += 100;
      }
      this.isOpenQuickReplay = true;
      this.changeSize();
    } else {
      this.bottomViewHeight -= 100;
      this.isOpenQuickReplay = false;
      this.changeSize();
    }
  }

  sendQuickMessage(msg) {
    if (msg.length === 0) {
      this.toast.warning('该快捷消息没有内容！');
      return;
    }
    this.sendMessage(msg);
  }

  inputChange(event) {
    this.chatManager.send(this.chatManager.tag.setChatInputText, { id: this.chattingInfoModel.listId, text: event });
  }

  setChangeChat(msg: ChatListModel) {
    if (!this.chattingInfoModel) {
      this.chattingInfoModel = new ChatInfoModel();
    }
    this.chatListModel = msg;
    this.chattingInfoModel.clientId = msg.clientId;
    this.chattingInfoModel.chattingId = msg.chatId;
    this.chattingInfoModel.listId = msg.id;
    this.chattingInfoModel.userId = msg.userId;
    this.chattingInfoModel.clientUser = msg.client;
    this.chattingInfoModel.inputText = msg.unSendText;
    this.chattingInfoModel.tagContent = msg.tagContent;
    this.getMessage();
  }

  // 获取聊天历史记录列表
  getMessage() {
    this.messageLi = 30;
    this.messagePi = 0;
    SqlliteManager.where<MessageModel>('userId = {0} and clientId = {1}', this.chattingInfoModel.userId, this.chattingInfoModel.clientId)
      .limit(this.messageLi).page(this.messagePi).orderBy('sendTime').desc().find(new MessageModel())
      .subscribe(res => {
        this.messageList = res;
        this.changeDetectorRef.detectChanges();
      });

    SqlliteManager.where('userId = {0} and clientId = {1}', this.chattingInfoModel.userId, this.chattingInfoModel.clientId)
      .update(new MessageModel(), `isRead = 'true'`).subscribe(res => {
      });

    SqlliteManager.where('userId = {0} and clientId = {1}', this.chattingInfoModel.userId, this.chattingInfoModel.clientId)
      .update(new ChatListModel(), `unReadNumber = '0'`).subscribe(res => {
      });
  }

  // 获取聊天历史记录列表
  loadMessage() {
    this.messageLi = 30;
    this.messagePi++;
    SqlliteManager.where<MessageModel>('userId = {0} and clientId = {1}', this.chattingInfoModel.userId, this.chattingInfoModel.clientId)
      .limit(this.messageLi).page(this.messagePi).orderBy('id').desc().find(new MessageModel())
      .subscribe(res => {
        res.forEach(item => {
          this.messageList.push(item);
        });
        this.changeDetectorRef.detectChanges();
      });

  }

  changeSize() {
    this.messageContentViewHeight = window.innerHeight - 61 - this.bottomViewHeight - 3;
  }

  mouseDown(event) {
    this.downY = event.screenY;
    this.isCanMoveBottomView = true;
  }

  changeBottomViewSize(event) {
    if (this.isCanMoveBottomView) {
      if (this.bottomViewHeight + (this.downY - event.screenY) <= (this.isOpenQuickReplay ? 250 : 150)
        || this.bottomViewHeight + (this.downY - event.screenY) >= (this.isOpenQuickReplay ? 400 : 300)) {
        return;
      }
      this.bottomViewHeight += this.downY - event.screenY;
      this.downY = event.screenY;
      this.changeSize();
    }
  }

  contentMoveBottom() {
    if (this.contentView) {
      this.contentView.nativeElement.scrollTop = this.contentView.nativeElement.scrollHeight;
    }
  }

  ngOnInit() {
    this.changeSize();
    fromEvent(window, `resize`).subscribe(res => {
      this.changeSize();
    });
  }
}
