
import { AxiosResponse } from 'axios';
import { ToastrService } from 'ngx-toastr';
import HttpBase from 'src/base/httpBase';
import HttpRequestData from './httpRequestData';
export default class Get extends HttpBase {
    constructor(private url: string, private body: any, private toast: ToastrService, private success?: (res: any) => void | undefined,
        private error?: (res: any) => void | undefined, private over?: (res: any | undefined) => void | undefined) {
        super();
    }

    request() {
        this.http.get(this.rootUrl + this.url, {
            params: this.body,
        }).then((res: AxiosResponse) => {
            switch (res.status) {
                case 401:
                    this.toast.error('身份信息过期，请重新登录！');
                    break;
                case 403:
                    this.toast.error('暂无权限哦');
                    break;
                default:
                    if (res.data.code === 1) {
                        if (this.success !== undefined) {
                            this.success(res.data);
                        }
                    } else {
                        if (this.error !== undefined) {
                            this.error(res.data);
                        }
                    }
            }
            if (this.over !== undefined) {
                this.over(res.data);
            }
        }).catch((error: AxiosResponse) => {
            if (this.over !== undefined) {
                this.over(null);
            }
        });
    }
}
