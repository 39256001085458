import { Component, Output, EventEmitter, Input, OnInit, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import HttpService from 'src/service/HttpService';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  @Output() loginChange = new EventEmitter();
  @Input() isShow: boolean;
  isHidePassword = true;
  password = '';
  account = '';
  constructor(private toast: ToastrService, private http: HttpService) {
  }

  login() {
    this.http.post('/customerServicerLogin', { account: this.account, password: this.password }, res => {
      this.loginChange.emit(res.data.token);
    }, error => {
      this.toast.error(error.msg);
    });

  }

}
