import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { ChatComponent } from './chat/chat.component';
import { ContentViewComponent } from './contentView/contentView.component';
import { ListViewComponent } from './listView/listView.component';
import { PopupBaseComponent } from 'src/widget/popupBase/popupBase.component';
import { PopupInputTagComponent } from 'src/widget/popupInputTag/popupInputTag.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PopupSettingComponent } from 'src/widget/popupSetting/popupSetting.component';
import { QuickReplayComponent } from './quickReply/quickReply.component';
import { PopupEmojiComponent } from 'src/widget/popupEmoji/popupEmoji.component';
import { DateTimePipe } from 'src/pipe/DateTimePipe';
import { StringToNumber } from 'src/pipe/StringToNumber';
import { PopupChatHistoryComponent } from 'src/widget/popupChatHistory/popupChatHistory.component';
import { PopupSearchComponent } from 'src/widget/popupSearch/popupSearch.component';
import { SipViewComponent } from 'src/widget/sipView/sipView.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChatComponent,
    ContentViewComponent,
    ListViewComponent,
    PopupBaseComponent,
    PopupInputTagComponent,
    PopupSettingComponent,
    QuickReplayComponent,
    PopupEmojiComponent,
    DateTimePipe,
    StringToNumber,
    PopupChatHistoryComponent,
    PopupSearchComponent,
    SipViewComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ToastrModule.forRoot(),

    FormsModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
