import { Component, Output, EventEmitter, Input, OnInit, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'popup-base',
  templateUrl: './popupBase.component.html',
  styleUrls: ['./popupBase.component.css']
})
export class PopupBaseComponent {
  @Input() isTransparent: boolean;
  @Input() isFullScreen: boolean;
  @Output() dismiss = new EventEmitter();

  x = 0;
  y = 0;
  isShow = false;

  constructor() {
  }


  show(x, y) {
    this.x = x;
    this.y = y;
    this.isShow = true;
  }

  close() {
    this.isShow = false;
    if (this.dismiss) {
      this.dismiss.emit();
    }
    return false;
  }
}
