import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebsocketService } from 'src/service/websocket.service';
import { fromEvent, Observable } from 'rxjs';
import { element } from 'protractor';

@Component({
  selector: 'app-quickreply',
  templateUrl: './quickReply.component.html',
  styleUrls: ['./quickReply.component.css']
})
export class QuickReplayComponent implements OnInit {
  quickReplyList = [];
  inputText = '';

  constructor(public webSocketService: WebsocketService, private toast: ToastrService) {
    const list = JSON.parse(localStorage.getItem('quickReply'));
    this.quickReplyList = list ? list : [];
  }


  add() {
    if (this.inputText.length === 0) {
      this.toast.warning('输入点东西在添加吧！');
      return;
    }
    if (this.quickReplyList.indexOf(this.inputText) !== -1) {
      this.toast.warning('该快捷消息已经存在了');
      return;
    }
    this.quickReplyList.push(this.inputText);
    localStorage.setItem('quickReply', JSON.stringify(this.quickReplyList));
    this.inputText = '';
  }

  remove(item) {
    this.quickReplyList.splice(this.quickReplyList.indexOf(item), 1);
    localStorage.setItem('quickReply', JSON.stringify(this.quickReplyList));
  }

  ngOnInit() {

  }
}
