<div *ngIf="isShow" (click)="close()" (contextmenu)="close()" class="back centerX">
  <div (click)="$event.stopPropagation()" class="popupBack"
    style="padding: 5px; margin-top: 5%;height: 80%;width: 300px;min-width: 500px;border-radius: 20px;">

    <div class="divX centerX" style="height: 8%;">
      <div style="font-size: 20px;color: #2f3542;margin-top: 10px;">
        <b>历史记录</b>
      </div>

    </div>
    <div #contentView class="messageContentView divY" (scroll)="scroll($event,contentView)" style="height:88%;">
      <div style="width: 100%;height: 100%;user-select: none;" class="center divY" *ngIf="messageList.length==0">
        <img src="../../assets//img//ic_没有聊天信息.svg" style="opacity:0.5">
        <div style="margin-top: 10px;font-size: 12px;">
          你跟用户暂无互动信息哦
        </div>
      </div>
      <div *ngFor="let item of messageList;let i =index" style="padding:10px 5px">

        <div class="center divX" *ngIf="i==messageList.length-1||item.sendTime-messageList[i+1].sendTime>1000*60*5">
          <div class="messageTime center">
            {{item.sendTime | dateTime}}
          </div>
        </div>
        <div class="center messageChatTime" [ngStyle]="{justifyContent: item.userType==1?'flex-end':'flex-start'}">
          {{item.sendTime | date:'yyyy/MM/dd HH:mm:ss'}}
        </div>
        <div *ngIf="item.userType==1" class="userMessageView divX">

          <img *ngIf="!item.sendSucces" ngbTooltip="发送失败，点击重新发送！" src="../../assets//img//ic_失败.svg"
            style="margin-right: 10px;cursor: pointer;">
          <div *ngIf="item.type==1" class="messageText">
            {{item.message}}
          </div>
          <div (click)="showImg(item)" *ngIf="item.type==2" class="messageImg">
            <img [src]="item.message" class="messageImgShow">
          </div>
          <div *ngIf="item.type==5" class="messageText">
            会话（<span style="color:#2f3542;">{{item.message}}</span>）已结束，等待用户评分

          </div>
          <div *ngIf="item.type==6" class="messageText divY">
            <div>
              用户已完成评分：
            </div>
            <div class="divX centerY" style="margin-top: 5px;">
              <span *ngFor="let gende of (item.message|string2numberArray)">
                🌹
              </span>
              <div style="margin-left: 5px;font-size: 10px;">
                <b>
                  {{item.message}}分
                </b>
              </div>
            </div>
          </div>
          <div style="position: relative;left: -10px;top:6px">
            <img src="../../assets//img/ic_右.svg" width="25px">
          </div>
        </div>
        <div *ngIf="item.userType==2" class="divX">
          <div style="position: relative;right:-4px;top:8px">
            <img src="../../assets//img/ic_左.svg" width="20px">
          </div>
          <div *ngIf="item.type==1" class="messageTextClient">
            {{item.message}}
          </div>
          <div (click)="showImg(item)" *ngIf="item.type==2" class="messageImageClient">
            <img [src]="item.message" class="messageImgShow">
            <div *ngIf="!item.isUpload" class="imgUploadProgrees center"
              [ngStyle]="{height:100-item.fileUploadProgress+'%'}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>