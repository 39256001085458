import { SqliteBase } from 'src/base/SqliteBase';

export class MessageModel extends SqliteBase{
    @SqliteBase.Parameter('INTEGER')
    @SqliteBase.PrimaryKey()
    @SqliteBase.AutoIncrement()
    @SqliteBase.NotNull()
    @SqliteBase.Unique()
    id = 0; // 消息id
    @SqliteBase.Parameter('INTEGER')
    serverId = 0; // 服务器消息id
    @SqliteBase.Parameter('INTEGER')
    userId = 0; // 客服自己的id
    @SqliteBase.Parameter('INTEGER')
    clientId = 0; // 用户id
    @SqliteBase.Parameter('INTEGER')
    userType = 0; // 消息发送人1自己 2客户
    @SqliteBase.Parameter('INTEGER')
    type = 0; // 1 文字 2图片
    @SqliteBase.Parameter('VARCHAR(255)')
    message = ''; // 消息内容
    @SqliteBase.Parameter('BLOB')
    sendSucces = false; //  是否发送成功
    @SqliteBase.Parameter('INTEGER')
    sendTime = 0; // 发送时间
    @SqliteBase.Parameter('BLOB')
    isRead = false; // 是否被阅读
    @SqliteBase.Parameter('INTEGER')
    fileUploadProgress = 0; // 文件上传进度
    @SqliteBase.Parameter('BLOB')
    isUpload = false; // 文件是否上传完毕
}
