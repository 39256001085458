declare let SIPml;
export default class Sip {
    sipStack: any;

    constructor() {
        console.error('初始化');
        const that = this;
        // SIPml.init((e) => {
        //     console.error('初始化完毕');
        //     const stack = new SIPml.Stack({
        //         realm: '120.24.37.61', impi: '02131836685', impu: 'sip:02131836685@120.24.37.61', password: 'mish9675',
        //         websocket_proxy_url: 'wss://127.0.0.1:4050', // optional
        //         events_listener: {
        //             events: 'started', listener: e2 => {
        //                 const callSession = stack.newSession('call-audiovideo', {
        //                     video_local: document.getElementById('video-local'), // <video id="video-local" .../>
        //                     video_remote: document.getElementById('video-remote'), // <video id="video-remote" .../>
        //                     audio_remote: document.getElementById('audio-remote') // <audio id="audio-remote" .../>
        //                 });
        //                 callSession.call('alice');
        //             }
        //         },
        //         sip_headers: [ // optional
        //             { name: 'User-Agent', value: 'IM-client/OMA1.0 sipML5-v1.0.0.0' },
        //             { name: 'Organization', value: 'Doubango Telecom' }
        //         ]
        //     });
        //     stack.start();
        // });
    }
}
