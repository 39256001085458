import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebsocketService } from 'src/service/websocket.service';
import { AudioService } from 'src/service/audio.service';
import { fromEvent, Observable } from 'rxjs';
import { ChatListModel } from 'src/model/ChatListModel';
import { ClientUserModel } from 'src/model/ClientUserModel';
import { PopupBaseComponent } from 'src/widget/popupBase/popupBase.component';
import { PopupInputTagComponent } from 'src/widget/popupInputTag/popupInputTag.component';
import { ChatManagerService } from 'src/service/chatManager.service';
import { element } from 'protractor';
import { AcceleratorsService } from 'src/service/accelerators.service';
import { AcceleratorsModel } from 'src/model/AcceleratorsModel';
import { PopupSettingComponent } from 'src/widget/popupSetting/popupSetting.component';
import { SqlliteManager } from 'src/utils/SqlliteManager';
import { MessageModel } from 'src/model/MessageModel';

@Component({
  selector: 'chat-list',
  templateUrl: './listView.component.html',
  styleUrls: ['./listView.component.css']
})
export class ListViewComponent implements OnInit {
  @Input() isShow: boolean;
  @Output() sizeChange = new EventEmitter();
  @ViewChild('openMiniWindow') openMiniWindow: PopupBaseComponent;
  @ViewChild('selectStatus') selectStatus: PopupBaseComponent;
  @ViewChild('tagContentPopup') tagContentPopup: PopupBaseComponent;
  @ViewChild('managerListPopup') managerListPopup: PopupBaseComponent;
  @ViewChild('inputTagPopup') inputTagPopup: PopupInputTagComponent;
  @ViewChild('settingPopup') settingPopup: PopupSettingComponent;


  list: ChatListModel[] = []; // 列表
  chatingId = 0; // 正在聊天id
  listId = 0; // 聊天列表id
  paixuSelectType = 0; // 排序类别0 时间 1 未读 2 标签
  userStatus = 0; // 用户状态 0 在线 1 忙碌 2 离线
  tagContent = ''; // 标签信息
  selectManagerListItem = new ChatListModel(); // 右键选中列表item
  isWindowModle = window.opener != null; // 是否是小窗模式
  userDetail: any; // 客服信息
  isGetVisibleChat = false; // 是否获取隐藏会话
  isSHowChatMessageTime = false; // 是否显示每天聊天记录的时间
  orderBy = 'lastMessageTime';
  searchText = '';

  constructor(private toast: ToastrService, private socketService: WebsocketService, private audioService: AudioService
    , private chatManager: ChatManagerService, accelerators: AcceleratorsService, private changeDetectorRef: ChangeDetectorRef) {
    const that = this;
    chatManager.addListener(chatManager.tag.setChatInputText).subscribe(res => this.setChatInputText(res));
    chatManager.addListener(chatManager.tag.backChating).subscribe(res => {
      this.chatingId = 0;
      this.listId = 0;
    });
    chatManager.addListener(chatManager.tag.changeChatList).subscribe((res: ChatListModel) => {
      this.list.splice(this.list.indexOf(res), 1);
      res.lastMessageTime = new Date().getTime();
      res.updateNoSub();
      this.list.unshift(res);
    });

    socketService.addSocketListener(socketService.keys.GetMyDetail).subscribe(data => {
      if (data.code === 1) {
        this.userDetail = data.data;
        socketService.sendMessageNoData(socketService.keys.GetChatingList);
      }
    });

    socketService.addSocketListener(socketService.keys.GetChatingList).subscribe(data => {
      if (data.code === 1) {
        SqlliteManager.where<ChatListModel>(`userId ={0} `, this.userDetail.id)
          .find(new ChatListModel()).subscribe(res => {
            res.forEach(chatlistItem => {
              let isHave = false;
              let item;
              data.data.forEach(chatingItem => {
                if (chatingItem.userImId === chatlistItem.clientId) {
                  isHave = true;
                  item = chatingItem;
                }
              });
              if (isHave) {
                if (chatlistItem.chatId === 0) {
                  chatlistItem.lastMessageTime = item.createTime;
                }
                chatlistItem.chatId = item.id;
                chatlistItem.updateNoSub();
              } else {
                chatlistItem.chatId = 0;
                chatlistItem.updateNoSub();
              }
            });
            data.data.forEach(chatingItem => {
              let isHave = false;
              res.forEach(chatlistItem => {
                if (chatingItem.userImId === chatlistItem.clientId) {
                  isHave = true;
                }
              });
              if (!isHave) {
                const chatListModel = new ChatListModel();
                chatListModel.chatId = chatingItem.id;
                chatListModel.clientId = chatingItem.userImId;
                chatListModel.userId = this.userDetail.id;
                chatListModel.lastMessageTime = chatingItem.createTime;
                chatListModel.saveNoSub();
              }
            });
            socketService.sendMessageNoData(socketService.keys.GetMessageNoRead);
          });
      }
    });

    socketService.addSocketListener(socketService.keys.GetMessageNoRead).subscribe(res => {
      if (res.code === 1) {
        let inList = `(`;
        res.data.forEach((data, index) => {
          inList += `${data.id}`;
          if (index < res.data.length - 1) {
            inList += ',';
          }
        });
        inList += ')';
        SqlliteManager.where<MessageModel>(`userId = {0} ${res.data.length > 0 ? `and serverId in ${inList}` : ''}`, this.userDetail.id)
          .find(new MessageModel()).subscribe(messageModel => {
            const list = [];
            res.data.forEach(data => {
              let isHave = false;
              messageModel.forEach(messageModelItem => {
                if (data.id === messageModelItem.serverId) {
                  isHave = true;
                }
              });
              if (!isHave) {
                list.push(data);
              }
            });
            list.forEach(data => {
              const modelItem = new MessageModel();
              modelItem.isRead = false;
              modelItem.message = data.message;
              modelItem.sendTime = data.createTime;
              modelItem.type = data.messageType;
              modelItem.userType = 2;
              modelItem.sendSucces = true;
              modelItem.userId = data.toImId;
              modelItem.serverId = data.id;
              modelItem.clientId = data.fromImId;
              modelItem.isUpload = true;
              modelItem.saveNoSub();
            });
            this.getChatList(this.userDetail.id);
            const thiat = this;
            setTimeout(() => {
              thiat.changeDetectorRef.detectChanges();
            }, 500);
          });
      } else {
        this.toast.warning(res.message);
      }
    });

    socketService.addSocketListener(socketService.keys.Receive).subscribe(data => {
      SqlliteManager.where<ChatListModel>(`userId ={0}`, this.userDetail.id).find(new ChatListModel()).subscribe(chatList => {
        let chatItem: ChatListModel = null;
        chatList.forEach(item => {
          if (item.clientId === data.data.fromImId && item.userId === data.data.toImId) {
            chatItem = item;
          }
        });
        if (!chatItem) {
          chatItem = new ChatListModel();
          let user: ClientUserModel = null;
          SqlliteManager.where<ClientUserModel>('clientId = {0}', data.data.fromImId).find(new ClientUserModel()).subscribe(client => {
            if (client.length === 0) {
              user = new ClientUserModel();
              user.avatar = '../../assets/img/ic_客服头像.svg';
              user.gender = '男';
              user.name = '获取中...';
              user.account = '';
              user.clientId = data.data.fromImId;
              user.saveNoSub();
            } else {
              user = client[0];
            }
            chatItem.clientId = data.data.fromImId;
            chatItem.client = user;
            chatItem.isTag = false;
            chatItem.isVisible = false;
            chatItem.tagContent = '';
            if (this.chatingId === data.data.chatId) {
              chatItem.unReadNumber = 0;
            } else {
              chatItem.unReadNumber = 1;
            }
            chatItem.lastMessage = data.data.message;
            chatItem.lastMessageTime = data.data.createTime;
            chatItem.lastMessageType = data.data.messageType;
            chatItem.userId = data.data.toImId;
            chatItem.chatId = data.data.chatId;
            chatItem.isVisible = false;
            chatItem.saveNoSub();
            this.list.unshift(chatItem);
            if (new Date().getTime() - chatItem.client.lastUpdateTime > 1000 * 60 * 60 * 24) {
              this.socketService.sendMessage(this.socketService.keys.GetChat, new Date().getTime(), { chatId: data.data.chatId });
            }
          });
        } else {
          if (this.chatingId === data.data.chatId) {
            chatItem.unReadNumber = 0;
          } else {
            chatItem.unReadNumber++;
          }
          chatItem.lastMessage = data.data.message;
          chatItem.lastMessageTime = data.data.createTime;
          chatItem.lastMessageType = data.data.messageType;
          if (chatItem.lastMessageType !== 6) {
            chatItem.chatId = data.data.chatId;
            if (this.listId === chatItem.id) {
              this.chatingId = data.data.chatId;
            }
          }
          chatItem.isVisible = false;
          let index = -1;
          this.list.forEach((listItem, i) => {
            if (listItem.id === chatItem.id) {
              chatItem.client = listItem.client;
              index = i;
            }
          });
          if (index !== -1) {
            this.list.splice(index, 1);
          }
          this.list.unshift(chatItem);
          chatItem.updateNoSub();
          if (chatItem.id === this.listId) {
            this.chatManager.send(this.chatManager.tag.setChangeChat, chatItem);
          }
          if (new Date().getTime() - chatItem.client.lastUpdateTime > 1000 * 60 * 60 * 24) {
            this.socketService.sendMessage(this.socketService.keys.GetChat, new Date().getTime(), { chatId: data.data.chatId });
          }
        }
        that.detectChanges();
        that.audioService.playMessageAudio();
      });
    });

    socketService.addSocketListener(socketService.keys.GetChat).subscribe(data => {
      if (data.code === 1) {
        SqlliteManager.where<ClientUserModel>(`clientId = {0}`, data.data.userImId).find(new ClientUserModel()).subscribe(res => {
          let client: ClientUserModel = null;
          if (res.length > 0) {
            res.forEach(clientItem => {
              clientItem.lastUpdateTime = new Date().getTime();
              clientItem.avatar = data.data.avatar;
              clientItem.account = data.data.account;
              clientItem.name = data.data.userName;
              clientItem.gender = data.data.gender ? data.data.gender : '男';
              clientItem.updateNoSub();
              client = clientItem;
            });
          } else {
            client = new ClientUserModel();
            client.lastUpdateTime = new Date().getTime();
            client.avatar = data.data.avatar;
            client.account = data.data.account;
            client.name = data.data.userName;
            client.clientId = data.data.userImId;
            client.gender = data.data.gender ? data.data.gender : '男';
            client.saveNoSub();
          }
          this.list.forEach(item => {
            if (item.clientId === data.data.userImId && item.userId === data.data.customerServerImId) {
              item.client = client;
            }
          });
          this.detectChanges();
        });
      }
    });

    socketService.addSocketListener(socketService.keys.CustomerServerLogin).subscribe(data => {
      if (data.code === 1) {
        socketService.sendMessageNoData(socketService.keys.GetMyDetail);
      }
    });

    socketService.addSocketListener(socketService.keys.CloseChat).subscribe(data => {
      if (data.code === 1) {
        this.list.forEach(item => {
          if (item.chatId === data.data.chatId) {
            item.chatId = 0;
            item.updateNoSub();
          }
        });
      } else {
        this.toast.error(data.message);
      }
    });

    let model = new AcceleratorsModel();
    model.otherKey = 'm';
    model.hasShift = false;
    model.hasControl = true;
    model.hasAlt = false;
    accelerators.addListener(model).subscribe(res => {
      this.openNewWindow();
    });

    model = new AcceleratorsModel();
    model.otherKey = 's';
    model.hasShift = false;
    model.hasControl = true;
    model.hasAlt = false;
    accelerators.addListener(model).subscribe(res => {
      this.openSetting();
    });

    model = new AcceleratorsModel();
    model.otherKey = 'v';
    model.hasShift = false;
    model.hasControl = false;
    model.hasAlt = true;
    accelerators.addListener(model).subscribe(res => {
      this.showHideChat();
    });
  }

  selectUserChat(clientId: number) {
    let chatItem;
    this.list.forEach(item => {
      if (item.clientId === clientId && item.userId === this.userDetail.id) {
        chatItem = item;
      }
    });
    if (chatItem) {
      this.changeChat(chatItem);
    } else {
      this.toast.warning('该用户会话已被隐藏，请允许显示隐藏会话后在进行聊天');
    }
  }

  searchChange(event) {
    console.log(event);
  }

  swhoHideChatMessageTime() {
    this.isSHowChatMessageTime = !this.isSHowChatMessageTime;
    this.chatManager.send(this.chatManager.tag.showHideChatMessageTime, this.isSHowChatMessageTime);
  }

  getChatList(userId: any) {
    this.list = [];
    SqlliteManager.where<ChatListModel>(`userId ={0} ${this.isGetVisibleChat ? '' : 'and isVisible=\'false\''}`, userId)
      .orderBy(this.orderBy).desc()
      .find(new ChatListModel()).subscribe(res => {
        res.forEach(item => {
          SqlliteManager.where<ClientUserModel>('clientId ={0}', item.clientId).find(new ClientUserModel()).subscribe(client => {
            if (client.length > 0) {
              item.client = client[0];
              if (new Date().getTime() - item.client.lastUpdateTime > 1000 * 60 * 60 * 24 && item.chatId !== 0) {
                this.socketService.sendMessage(this.socketService.keys.GetChat, new Date().getTime(), { chatId: item.chatId });
              }
              this.list.push(item);
              this.detectChanges();
            } else {
              item.client = new ClientUserModel();
              console.log(item);
              this.list.push(item);
              this.socketService.sendMessage(this.socketService.keys.GetChat, new Date().getTime(), { chatId: item.chatId });
            }
          });

          SqlliteManager.where<MessageModel>('userId={0} and clientId={1} and isRead=\'false\'', userId, item.clientId)
            .find(new MessageModel()).subscribe(messageModel => {
              if (messageModel.length > 0) {
                item.unReadNumber = messageModel.length;
                item.lastMessageType = messageModel[messageModel.length - 1].type;
                item.lastMessage = messageModel[messageModel.length - 1].message;
                item.lastMessageTime = messageModel[messageModel.length - 1].sendTime;
                item.updateNoSub();
              }
            });
        });
      });
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  openSetting() {
    this.settingPopup.show();
  }

  showHideChat() {
    this.isGetVisibleChat = !this.isGetVisibleChat;
    this.getChatList(this.userDetail.id);
  }

  openNewWindow() {
    if (!window.opener) {
      window.open(window.location.href, '客服系统', 'channelmode=yes,fullscreen=yes,height=600, width=1000, depended=no,menubar =no,location=no,titlebar=no,toolbar=no,z-look=yes, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no');
      this.openMiniWindow.show(0, 0);
      setTimeout(() => {
        window.location.href = 'about:blank';
        window.close();
      }, 3000);
    }
  }

  setChatInputText(msg) {
    this.list.forEach(item => {
      if (item.id === msg.id) {
        item.unSendText = msg.text;
        item.updateNoSub();
      }
    });
  }

  changeChat(item: ChatListModel) {
    this.chatingId = item.chatId;
    this.listId = item.id;
    item.unReadNumber = 0;
    this.socketService.sendMessage(this.socketService.keys.Read2, new Date().getTime(), { toImId: item.clientId });
    this.chatManager.send(this.chatManager.tag.setChangeChat, item);
  }

  // 开始调整宽度
  mouseDown(event) {
    this.sizeChange.emit({ isCanMoveListView: true, downX: event.screenX });
  }

  selectPaixuType(type) {
    this.paixuSelectType = type;
    switch (type) {
      case 0:
        this.orderBy = 'lastMessageTime';
        break;
      case 1:
        this.orderBy = 'unReadNumber';
        break;
      case 2:
        this.orderBy = 'chatId';
        break;
    }
    this.getChatList(this.userDetail.id);
  }

  setStatus(event) {
    this.selectStatus.show(event.x, event.y);
  }

  selectUserStatus(status) {
    this.userStatus = status;
    this.selectStatus.close();
  }

  showTag(event, tag) {
    this.tagContentPopup.show(event.x, event.y);
    this.tagContent = tag;
  }

  removeList() {
    if (!this.selectManagerListItem.isVisible) {
      if (this.selectManagerListItem.unReadNumber > 0) {
        this.toast.warning('该用户还有未处理消息，无法隐藏会话！');
        return;
      }
      if (this.chatingId === this.selectManagerListItem.id) {
        this.chatingId = 0;
        this.listId = 0;
        this.chatManager.send(this.chatManager.tag.removeChatting, null);
      }
      this.selectManagerListItem.isVisible = true;
      this.list.splice(this.list.indexOf(this.selectManagerListItem), 1);
      this.managerListPopup.close();
    } else {
      this.selectManagerListItem.isVisible = false;
    }
    this.selectManagerListItem.updateNoSub();
  }

  addListTag() {
    this.inputTagPopup.show();
    this.managerListPopup.close();
  }

  loginOut() {
    localStorage.setItem('token', null);
    location.reload();
  }

  managerList(event, item) {
    this.selectManagerListItem = item;
    this.managerListPopup.show(event.x, event.y);
    return false;
  }

  removeListTag() {
    this.selectManagerListItem.isTag = false;
    this.selectManagerListItem.tagContent = '';
    this.selectManagerListItem.updateNoSub();
    this.managerListPopup.close();
    this.list.forEach(item => {
      if (item.id === this.listId) {
        this.chatManager.send(this.chatManager.tag.setChangeChat, item);
      }
    });

  }

  inputTagChange(tag) {
    this.selectManagerListItem.isTag = true;
    this.selectManagerListItem.tagContent = tag;
    this.selectManagerListItem.updateNoSub();
    this.list.forEach(item => {
      if (item.id === this.listId) {
        this.chatManager.send(this.chatManager.tag.setChangeChat, item);
      }
    });
  }

  ngOnInit() {
  }
}
