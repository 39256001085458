declare let window;
import 'reflect-metadata';
import { Observable, Subscriber } from 'rxjs';
import { ChatListModel } from 'src/model/ChatListModel';
import { SqliteBaseListener } from './SqliteBaseListener';

export abstract class SqliteBase {
    private static dataBaseListener: SqliteBaseListener; // 保存监听
    public static class: SqliteBase; // 实例化类，用于反射操作


    // 添加保存监听
    public static setListener(dataBaseListener: SqliteBaseListener) {
        SqliteBase.dataBaseListener = dataBaseListener;
    }

    // 保存数据
    public save(): Observable<any> {
        let fieldList = ``;
        let valueList = ``;
        const entries = this.getParameterEntriesKes();
        entries.forEach((item, index) => {
            if (!Reflect.hasMetadata('AutoIncrement', this, item[0])) {// 如果不是自增长，都加进去
                fieldList += item[0];
                valueList += `\'${item[1]}\'`;
                if (index < entries.length - 1) {
                    fieldList += ',';
                    valueList += ',';
                }
            }
        });
        return SqliteBase.dataBaseListener.save(`INSERT INTO ${this.constructor.name} (${fieldList}) VALUES(${valueList})`);
    }
    // 保存数据
    public saveNoSub() {
        this.save().subscribe();
    }

    // 修改数据
    public update(): Observable<any> {
        const primaryKey = this.getParameterPrimaryKey();
        if (primaryKey === null) {
            console.log(` ${this.constructor.name}没有主键，无法使用update`);
            return;
        }
        let sql = ``;
        const entries = this.getParameterEntriesKes();
        entries.forEach((item, index) => {
            if (!Reflect.hasMetadata('AutoIncrement', this, item[0])) {// 如果不是自增长，都加进去
                sql += ` ${item[0]}=\'${item[1]}\'`;
                if (index < entries.length - 1) {
                    sql += ',';
                }
            }
        });
        return SqliteBase.dataBaseListener
            .update(`UPDATE ${this.constructor.name} SET ${sql} WHERE  ${primaryKey[0]}=\'${primaryKey[1]}\'`);
    }
    // 修改数据
    public updateNoSub() {
        this.update().subscribe();
    }

    // 删除数据
    public delete(): Observable<any> {
        const primaryKey = this.getParameterPrimaryKey();
        if (primaryKey === null) {
            console.log(` ${this.constructor.name}没有主键，无法使用update`);
            return;
        }
        return SqliteBase.dataBaseListener.delete(`DELETE FROM  ${this.constructor.name} WHERE  ${primaryKey[0]}=\'${primaryKey[1]}\'`);
    }
    // 删除数据
    public deleteNoSub() {
        this.delete().subscribe();
    }

    // 获取创建数据库sql语句
    public create(): Observable<any> {
        const keys = this.getParameterKes();
        let sql = '';
        keys.forEach((item, index) => {
            if (Reflect.hasMetadata('Parameter', this, item)) {
                sql += `${item} ${Reflect.getMetadata('Parameter', this, item)} `;
                if (Reflect.hasMetadata('PrimaryKey', this, item)) {
                    sql += `${Reflect.getMetadata('PrimaryKey', this, item)}\n`;
                }
                if (Reflect.hasMetadata('AutoIncrement', this, item)) {
                    sql += `${Reflect.getMetadata('AutoIncrement', this, item)}\n`;
                }
                if (Reflect.hasMetadata('Unique', this, item)) {
                    sql += `${Reflect.getMetadata('Unique', this, item)}\n`;
                }
                if (Reflect.hasMetadata('NotNull', this, item)) {
                    sql += `${Reflect.getMetadata('NotNull', this, item)}\n`;
                }
                if (index < keys.length - 1) {
                    sql += ',';
                }
            }
        });
        return SqliteBase.dataBaseListener.create(`create table if not exists ${this.constructor.name}(${sql})`);
    }
    // 获取创建数据库sql语句
    public createNoSub() {
        this.create().subscribe();
    }

    // 查询作为主键的字段
    getParameterPrimaryKey(): [string, any] | null {
        let primaryKey: [string, any] = null;
        this.getParameterEntriesKes().forEach(item => {
            if (Reflect.hasMetadata('PrimaryKey', this, item[0])) {
                primaryKey = item;
                return;
            }
        });
        return primaryKey;
    }

    // 获取所有Parameter注解的key跟value
    getParameterEntriesKes(): [string, any][] {
        const keys: [string, any][] = [];
        Object.entries(this).forEach(item => {
            if (Reflect.hasMetadata('Parameter', this, item[0])) {
                keys.push(item);
            }
        });
        return keys;
    }

    // 获取所有Parameter注解的参数
    getParameterKes(): string[] {
        const keys: string[] = [];
        Object.keys(this).forEach(item => {
            if (Reflect.hasMetadata('Parameter', this, item)) {
                keys.push(item);
            }
        });
        return keys;
    }

    // class注解实例,暂时无用
    // tslint:disable-next-line: member-ordering
    public static DataBase(name: string): ClassDecorator {
        return target => {
            Reflect.defineMetadata('database', name, target);
        };
    }


    // 参数类型以及注明该参数参与数据库
    // tslint:disable-next-line: member-ordering
    public static Parameter(type: string) {
        return Reflect.metadata('Parameter', type);
    }

    // 是否为主键
    // tslint:disable-next-line: member-ordering
    public static PrimaryKey() {
        return Reflect.metadata('PrimaryKey', 'PRIMARY KEY');
    }

    // 是否唯一
    // tslint:disable-next-line: member-ordering
    public static Unique() {
        return Reflect.metadata('Unique', 'UNIQUE');
    }

    // 是否不准为空
    // tslint:disable-next-line: member-ordering
    public static NotNull() {
        return Reflect.metadata('NotNull', 'NOT NULL');
    }

    // 是否自增长
    // tslint:disable-next-line: member-ordering
    public static AutoIncrement() {
        return Reflect.metadata('AutoIncrement', 'AUTOINCREMENT');
    }
}
