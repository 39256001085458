import { Pipe, PipeTransform } from '@angular/core';
import { start } from 'repl';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'dateTime' })
export class DateTimePipe implements PipeTransform {
    transform(value: number): string {
        const time = new Date(value);
        const now = new Date();
        let strTime = '';
        if (time.getFullYear() !== now.getFullYear()) {
            strTime += time.getFullYear() + '年';
            strTime += this.isNeedAdd0(time.getMonth() + 1) + '月';
            strTime += this.isNeedAdd0(time.getDate()) + '日';
        } else if (time.getMonth() !== now.getMonth()) {
            strTime += this.isNeedAdd0(time.getMonth() + 1) + '月';
            strTime += this.isNeedAdd0(time.getDate()) + '日';
        } else {
            if (time.getDate() !== now.getDate()) {
                switch (now.getDate() - time.getDate()) {
                    case 1:
                        strTime += '昨天';
                        break;
                    case 2:
                        strTime += '前天';
                        break;
                    default:
                        strTime += this.isNeedAdd0(time.getMonth() + 1) + '月';
                        strTime += this.isNeedAdd0(time.getDate()) + '日';
                }
            }
        }
        strTime += ` ${this.isNeedAdd0(time.getHours())}:${this.isNeedAdd0(time.getMinutes())}`;
        return strTime;
    }

    private isNeedAdd0(num: number): string {
        if (num < 10) {
            return `0${num}`;
        } else {
            return num + '';
        }
    }
}
