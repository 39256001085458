import { Injectable } from '@angular/core';
import { Subject, Observer, Observable, Subscriber } from 'rxjs';
import { AcceleratorsModel } from 'src/model/AcceleratorsModel';
@Injectable({
  providedIn: 'root',
})
export class AcceleratorsService {
  acceleratorList: AcceleratorsListenerModel[] = [];

  constructor() {
  }

  addListener(model: AcceleratorsModel): Observable<any> {
    return new Observable(observer => {
      let isHave = false;
      this.acceleratorList.forEach(element => {
        isHave = element.model.equals(model);
        return;
      });
      if (isHave) {
        observer.error(`${model.toString()}已存在，但可以正常使用！`);
      }
      this.acceleratorList.push({ ob: observer, model });
    });
  }

  send(model: AcceleratorsModel): boolean {
    let isHave = false;
    this.acceleratorList.forEach(element => {
      if (element.model.equals(model)) {
        isHave = true;
        element.ob.next();
      }
    });
    return isHave;
  }
}

class AcceleratorsListenerModel {
  ob: Subscriber<any>;
  model: AcceleratorsModel;
}
