import { Component, Output, EventEmitter, Input, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageModel } from 'src/model/MessageModel';
import { WebsocketService } from 'src/service/websocket.service';
import { PopupBaseComponent } from '../popupBase/popupBase.component';
@Component({
  selector: 'popup-history',
  templateUrl: './popupChatHistory.component.html',
  styleUrls: ['./popupChatHistory.component.css']
})
export class PopupChatHistoryComponent implements AfterViewInit {
  isShow = false;
  size = 50;
  page = 0;
  data: any;
  messageList: MessageModel[] = [];
  constructor(private toast: ToastrService, private webSocket: WebsocketService) {
    webSocket.addSocketListener(webSocket.keys.GetMessageRec).subscribe(res => {
      if (res.code === 1) {
        res.data.forEach(data => {
          const messageModel = new MessageModel();
          messageModel.isRead = true;
          messageModel.message = data.message;
          messageModel.sendTime = data.createTime;
          messageModel.type = data.messageType;
          messageModel.userType = data.sendImType;
          messageModel.sendSucces = true;
          messageModel.userId = data.toImId;
          messageModel.clientId = data.fromImId;
          messageModel.isUpload = true;
          this.messageList.push(messageModel);
        });
      }
    });
  }

  ok() {
    this.isShow = false;
  }

  ngAfterViewInit() {
  }

  scroll(event, div) {
    if (div.scrollTop === 0) {
      this.page++;
      this.getList();
    }
  }

  showImg(item) {
    if (item.isUpload) {
      window.open(item.message, '_blank');
    } else {
      window.open(item.message.changingThisBreaksApplicationSecurity, '_blank');
    }
  }

  show(data: any) {
    this.isShow = true;
    this.size = 50;
    this.page = 0;
    this.data = data;
    this.messageList = [];
    this.getList();
  }

  getList() {
    this.webSocket.sendMessage(this.webSocket.keys.GetMessageRec, new Date().getTime()
      , { page: this.page, size: this.size, toImId: this.data.clientId });
  }

  close() {
    this.isShow = false;
  }

}
