import { Console } from 'console';

export class Util {
    public static stringFormat(keys: string, values: any[]): string {
        if (values.length === 0) {
            return keys;
        }
        let str = keys;
        for (let i = 0; i < values.length; i++) {
            const re = new RegExp('\\{' + (i) + '\\}', 'gm');
            str = str.replace(re, `\'${values[i]}\'`);
        }
        return str;
    }

    // 判断是否是pc
    public static isWindow() {
        const sUserAgent = navigator.userAgent.toLowerCase();
        return !(/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent));
    }
}
