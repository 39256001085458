import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Get from 'src/utils/http/get';
import Post from 'src/utils/http/post';

@Injectable({
    providedIn: 'root',
})
export default class HttpService {
    constructor(private toast: ToastrService) {
    }

    get(url: string, body: any, success?: (res: any) => void | undefined,
        error?: (res: any) => void | undefined, over?: (res: any | undefined) => void | undefined) {
        const get = new Get(url, body, this.toast, success, error, over);
        get.request();
    }

    post(url: string, body: any, success?: (res: any) => void | undefined,
        error?: (res: any) => void | undefined, over?: (res: any | undefined) => void | undefined) {
        const post = new Post(url, body, this.toast, success, error, over);
        post.request();
    }
}
