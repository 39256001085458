import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { AcceleratorsModel } from 'src/model/AcceleratorsModel';
import { ChatListModel } from 'src/model/ChatListModel';
import { ClientUserModel } from 'src/model/ClientUserModel';
import { MessageModel } from 'src/model/MessageModel';
import { AcceleratorsService } from 'src/service/accelerators.service';
import HttpService from 'src/service/HttpService';
import { WebsocketService } from 'src/service/websocket.service';
import Sip from 'src/utils/sip/Sip';
import { SqlliteManager } from 'src/utils/SqlliteManager';
import { ChatComponent } from './chat/chat.component';
import { LoginComponent } from './login/login.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('chat') chat: ChatComponent;
  isHidePassword = true;
  isLogin = localStorage.getItem('token') !== 'null';
  hasAlt = false;
  hasControl = false;
  hasShift = false;

  constructor(private toast: ToastrService, private accelerators: AcceleratorsService, private socketService: WebsocketService) {
    const sip = new Sip();
    SqlliteManager.init(
      [
        new ChatListModel(),
        new ClientUserModel(),
        new MessageModel()
      ]
    );
    socketService.addSocketListener(socketService.keys.CustomerServerLogin).subscribe(data => {
      if (data.code === 0) {
        socketService.removeAllListener();
        toast.error(data.message);
        this.isLogin = false;
      }
    });
  }

  @HostListener('window:keyup', ['$event'])
  doSomethings(event) {
    switch (event.key) {
      case 'Shift':
        this.hasShift = false;
        break;
      case 'Control':
        this.hasControl = false;
        break;
      case 'Alt':
        this.hasAlt = false;
        break;
    }
    return false;
  }

  @HostListener('window:keydown', ['$event'])
  doSomething(event) {
    switch (event.key) {
      case 'Shift':
        this.hasShift = true;
        break;
      case 'Control':
        this.hasControl = true;
        break;
      case 'Alt':
        this.hasAlt = true;
        break;
      default:
        if (this.hasAlt || this.hasControl || this.hasShift) {
          const model = new AcceleratorsModel();
          model.otherKey = event.key;
          model.hasShift = this.hasShift;
          model.hasControl = this.hasControl;
          model.hasAlt = this.hasAlt;
          this.hasShift = false;
          this.hasControl = false;
          this.hasAlt = false;
          return !this.accelerators.send(model);
        }
    }
    return true;
  }

  ngAfterViewInit() {
    if (this.isLogin) {
      this.chat.connect(localStorage.getItem('token'));
    }
  }

  loginChange(event) {
    this.toast.success(`登录成功！`);
    this.isLogin = true;
    this.chat.connect(event);
  }

}
