<div class="quickReplyList divX2">
    <div class="quickReplyItem center divX" *ngFor="let item of quickReplyList">
        <div>
            {{item}}
        </div>
        <img (click)='remove(item)' src="../../assets//img/ic_删除.svg" style="cursor: ;">
    </div>
    <div class="quickReplyItem center " style="padding: 2px;">
        <input [(ngModel)]="inputText" placeholder="添加" class="addInput">
        <div (click)="add()" class="addButton center">
            添加
        </div>
    </div>
</div>