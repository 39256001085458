
import Axios, { AxiosInstance, AxiosResponse } from 'axios';
export default abstract class HttpBase {
    // rootUrl = 'http://192.168.3.15:8080/';
    rootUrl = 'https://web.mish.top/';
    http: AxiosInstance;
    token = '';

    constructor() {
        this.http = this.createAxiosInstance();
        this.setHttpFilter();
    }


    /**
     * 设置默认请求数据
     */
    createAxiosInstance(): AxiosInstance {
        return Axios.create({
            baseURL: this.rootUrl,
            timeout: 10000,
            headers: {
                token: localStorage.getItem('token'),
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization,Origin, X-Requested-With, Content-Type, Accept',
                'Access-Control-Allow-Methods': 'GET,POST',
                'accept': '*/*'
            }
        });
    }

    /**
     * 设置过滤器
     */
    setHttpFilter() {
        this.http.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            error => {
                return Promise.reject(error.response);
            }
        );
    }



    /**
     * 开始请求
     */
    abstract request(): void;
}
