<div class="popupBack back">
    <div style="width: 100%;height:250px;">
        <div class="content divX2">

            <div *ngFor='let item of emojiList[showIndex];let i = index'>
                <div class="emoji center" (click)="select(item,i)" [ngbTooltip]='emojiTipList[showIndex][i]'>{{item}}
                </div>
            </div>
        </div>
    </div>

    <div class="bottom divX centerY">
        <div *ngFor='let item of emojiList;let i=index' (click)='showIndex=i' class="emoji center"
            [ngStyle]="{background:showIndex==i?'#dfe4ea':'#fff'}">
            {{item[0]}}
        </div>
    </div>

    <div style="width: 100%;position: absolute;bottom: -15px;" class="center">
        <img src="../../assets//img//ic_下.svg" width="25px" style="z-index: 99;">
        <img src="../../assets//img//ic_下2.svg" width="30px"
            style="position: absolute;z-index: 98;margin-top: 2px;opacity: 0.5;">
    </div>
</div>