import { Component, Output, EventEmitter, Input, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PopupBaseComponent } from '../popupBase/popupBase.component';
@Component({
  selector: 'popup-setting',
  templateUrl: './popupSetting.component.html',
  styleUrls: ['./popupSetting.component.css']
})
export class PopupSettingComponent implements AfterViewInit {
  isShow = false;
  constructor(private toast: ToastrService) {
  }

  ok() {
    this.isShow = false;
  }

  ngAfterViewInit() {
  }

  show() {
    this.isShow = true;
  }

  close() {
    this.isShow = false;
  }

}
