import { Pipe, PipeTransform } from '@angular/core';
import { start } from 'repl';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'string2numberArray' })
export class StringToNumber implements PipeTransform {
    transform(value: string): any[] {
        let num: number = 0;
        try {
            num = Number(value);
        } catch {
            num = 0;
        }
        const array = [];
        for (let i = 0; i < num; i++) {
            array.push(i);
        }
        return array;
    }
}
