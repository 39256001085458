import { Observable } from 'rxjs';
import { SqliteBase } from 'src/base/SqliteBase';
import { SqliteBaseListener } from 'src/base/SqliteBaseListener';
import { ChatListModel } from 'src/model/ChatListModel';
import { SqliteController } from './SqliteController';
import { SqliteControllerListener } from './SqliteControllerListener';

declare let window;

export class SqlliteManager implements SqliteBaseListener {
    private static instance = new SqlliteManager();

    dbName = 'bixin';
    dbVersion = '1.0';
    dbDisplayName = '彼昕数据库';
    db: any; // 数据库操作对象

    // 初始化数据库信息
    public static init(sqlliteBase: SqliteBase[]) {
        SqlliteManager.instance.openDataBase(); // 打开数据库
        SqliteBase.setListener(SqlliteManager.instance);
        sqlliteBase.forEach(item => {
            item.createNoSub();
        });
    }

    // 查询
    public static findAll<T extends SqliteBase>(sqlliteBase: T): Observable<T[]> {
        const listener: SqliteControllerListener<T> = {
            execute(sql: string, model: T): Observable<T[]> {
                return new Observable<T[]>(observe => {
                    SqlliteManager.instance.execute(sql).subscribe(res => {
                        observe.next(SqlliteManager.instance.sqlResultToModel<T>(res, model));
                    });
                });
            }
        };
        return new SqliteController<T>(listener).find(sqlliteBase);
    }

    // 开始weher条件查询
    public static where<T extends SqliteBase>(keys: string, ...values: any[]): SqliteController<T> {
        const listener: SqliteControllerListener<T> = {
            execute(sql: string, model: T): Observable<T[]> {
                return new Observable<T[]>(observe => {
                    SqlliteManager.instance.execute(sql).subscribe(res => {
                        observe.next(SqlliteManager.instance.sqlResultToModel<T>(res, model));
                    });
                });
            }
        };
        const controller = new SqliteController<T>(listener);
        return controller.where(keys, values);
    }

    // 开始拼接查询
    public static create<T extends SqliteBase>(): SqliteController<T> {
        const listener: SqliteControllerListener<T> = {
            execute(sql: string, model: T): Observable<T[]> {
                return new Observable<T[]>(observe => {
                    SqlliteManager.instance.execute(sql).subscribe(res => {
                        observe.next(SqlliteManager.instance.sqlResultToModel<T>(res, model));
                    });
                });
            }
        };
        return new SqliteController<T>(listener);
    }

    // 查询结果实例化
    sqlResultToModel<T extends SqliteBase>(result: any[], model: T): T[] {
        const resultList: T[] = [];
        for (const item of result) {
            const itemKeys = Object.entries(item);
            try {
                let t: any;
                if (model === null) {
                    t = Object();
                } else {
                    t = Object.create(model);
                }
                itemKeys.forEach(entries => {
                    if (typeof (t[entries[0]]) === 'boolean') {
                        t[entries[0]] = entries[1] === 'true' ? true : false;
                    } else {
                        t[entries[0]] = entries[1];
                    }
                });
                resultList.push(t);
            } catch (res) {
                console.error(res);
            }
        }
        return resultList;
    }

    // 打开数据库
    openDataBase() {
        this.db = window.openDatabase(this.dbName, this.dbVersion, this.dbDisplayName, 1024 * 1024 * 50);
    }

    // 执行sql
    execute(sql): Observable<any> {
        console.log(sql);
        return new Observable(observer => {
            if (this.db === null) {
                observer.error('为初始化，请调用SqlliteManager.init');
                return;
            }
            this.db.transaction(tx => {
                tx.executeSql(sql, [], ((transaction, result) => {
                    observer.next(result.rows);
                }), (error => {
                    observer.error(error);
                }));
            });
        });
    }

    // 保存监听
    save(sql: string): Observable<any> {
        return this.execute(sql);
    }
    // 删除监听
    delete(sql: string): Observable<any> {
        return this.execute(sql);
    }
    // 更新监听
    update(sql: string): Observable<any> {
        return this.execute(sql);
    }
    // 创建监听
    create(sql: string): Observable<any> {
        return this.execute(sql);
    }
}


