import { Component, Output, EventEmitter, Input, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ChatListModel } from 'src/model/ChatListModel';
import { ClientUserModel } from 'src/model/ClientUserModel';
import { MessageModel } from 'src/model/MessageModel';
import { SqlliteManager } from 'src/utils/SqlliteManager';
import { PopupBaseComponent } from '../popupBase/popupBase.component';
@Component({
  selector: 'popup-search',
  templateUrl: './popupSearch.component.html',
  styleUrls: ['./popupSearch.component.css']
})
export class PopupSearchComponent {
  @Output() selectChange = new EventEmitter();

  searchText = '';
  clientList: ClientUserModel[] = [];
  messageList: any[] = [];
  constructor(private toast: ToastrService) {
  }

  show(event, userId: string) {
    this.searchText = event;
    if (this.searchText) {
      SqlliteManager.where<ClientUserModel>('ChatListModel.userId={0} and ClientUserModel.account like {1} or ClientUserModel.name like  {2}',
        userId, `%${event}%`, `%${event}%`
      )
        .join('join ClientUserModel on ChatListModel.clientId=ClientUserModel.clientId')
        .findJoin('ChatListModel', new ClientUserModel()).subscribe(res => {
          this.clientList = res;
        });

      SqlliteManager.where<any>('userId = {0} and message like {1}', userId, `%${event}%`)
        .join('join ClientUserModel on MessageModel.clientId=ClientUserModel.clientId')
        .orderBy('MessageModel.sendTime')
        .desc()
        .findAny('MessageModel').subscribe(res => {
          console.log(res);
          this.messageList = res;
        });
    } else {
      this.clientList = [];
      this.messageList = [];
    }
  }

  select(clientId: number) {
    this.selectChange.emit(clientId)
  }

}
