import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebsocketService } from 'src/service/websocket.service';
import { fromEvent, Observable } from 'rxjs';
import { element } from 'protractor';
import { Util } from 'src/utils/Util';
import { ChatManagerService } from 'src/service/chatManager.service';
declare let window;
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  @Input() isShow: boolean;
  listViewWidth = 270;
  contentViewWdith = 0;
  downX = 0;
  isCanMoveListView = false;
  isShowChatView = Util.isWindow();
  isWindow = Util.isWindow();

  constructor(public webSocketService: WebsocketService, private toast: ToastrService, private chatManager: ChatManagerService,
    private socketService: WebsocketService) {
    this.chatManager.addListener(this.chatManager.tag.setChangeChat).subscribe(res => {
      this.isShowChatView = true;
    });

    chatManager.addListener(chatManager.tag.backChating).subscribe(res => {
      this.isShowChatView = false;
    });

  }

  public connect(token) {
    localStorage.setItem('token', token);
    this.webSocketService.connect(`${token}`);
  }


  /**
   * 滑动更改列表宽度
   */
  changeListViewSize(event) {
    if (this.isCanMoveListView) {
      if (this.listViewWidth - (this.downX - event.screenX) <= 250 || this.listViewWidth - (this.downX - event.screenX) >= 500) {
        return;
      }
      this.listViewWidth -= this.downX - event.screenX;
      this.downX = event.screenX;
      this.changeSize();
    }
  }

  /**
   * 列表宽边改变
   */
  listViewSizeChange(event) {
    this.isCanMoveListView = event.isCanMoveListView;
    this.downX = event.downX;
  }


  ngOnInit() {
    if (!this.isWindow) {
      this.listViewWidth = window.innerWidth;
      this.contentViewWdith = window.innerWidth;
    }
    this.changeSize();
    fromEvent(window, `resize`).subscribe(res => {
      this.changeSize();
    });
  }

  changeSize() {
    if (this.isWindow) {
      this.contentViewWdith = window.innerWidth - this.listViewWidth;
    }
  }

}
