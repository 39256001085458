<div class="mainView divX">
    <div class="listView divY">
        <div style="margin:0px 10px 0px 20px;position: relative;">
            <div class="divX" style="position: relative;margin-top: 20px;">
                <img (contextmenu)="loginManager.show($event.x,$event.y);false;"
                    [src]="userDetail?(userDetail.avatar?userDetail.avatar:'../../assets/img/ic_客服头像.svg'):'../../assets/img/ic_客服头像.svg'"
                    class="userImg">
                <div class="userInfo centerX divY">
                    <div style="font-size: 12px;">
                        {{userDetail?userDetail.userName:'获取中...'}}
                    </div>
                    <div style="font-size: 12px;margin-top: 2px;color: #bbb;">
                        {{userDetail?userDetail.account:'获取中...'}}
                    </div>
                    <div (click)="setStatus($event)"
                        [ngClass]="{userStatusOnline:userStatus==0,userStatusFanmang:userStatus==1,userStatusOffline:userStatus==2}"
                        class="userStatus divX centerY">
                        <div
                            [ngClass]="{userStatusOnlineView:userStatus==0,userStatusFanmangView:userStatus==1,userStatusOfflineView:userStatus==2}">
                        </div>
                        <div [ngStyle]="{color: userStatus==0?'#2ed573':userStatus==1?'#ff7f50':'#ced6e0'}">
                            {{userStatus==0?'在线':userStatus==1?'繁忙':'离线'}}
                        </div>
                        <img src="../../assets/img/ic_下拉.svg" width="18px" style="margin-left: 5px;">
                    </div>
                </div>
            </div>
            <div class="search centerY">
                <input (ngModelChange)="popupSearch.show($event,userDetail.id)" [(ngModel)]="searchText"
                    class="searchInput" placeholder="搜索" />
                <img (click)="popupSearch.show('',userDetail.id);searchText=''"
                    [src]="searchText?'../../assets//img/ic_删除2.svg':'../../assets//img/ic_搜索.svg'" width="14px"
                    style="cursor: pointer;position:absolute;right: 15px;">
            </div>
            <popup-search (selectChange)="selectUserChat($event)" #popupSearch style="position: absolute;width: 95%;">

            </popup-search>
            <div class="divX" style="margin-top: 10px;">
                <div (click)="openSetting()" class="settingView center">
                    <img src="../../assets//img/ic_设置.svg" width="16px">
                </div>
                <div class="windowsModle center" (click)="openNewWindow()">
                    <img [src]="isWindowModle?'../../assets/img/ic_小窗开启2.svg':'../../assets//img/ic_小窗开启1.svg'"
                        [ngbTooltip]="isWindowModle?'已开启小窗模式':'开启小窗模式'" width="16px">
                </div>
                <div class="showHideChat center" (click)="showHideChat()">
                    <img [src]="isGetVisibleChat?'../../assets/img/ic_显示2.svg':'../../assets//img/ic_显示.svg'"
                        [ngbTooltip]="isGetVisibleChat?'已显示隐藏会话1':'显示隐藏会话'" width="16px">
                </div>
                <div class="showHideChat center" (click)="swhoHideChatMessageTime()">
                    <img [src]="isSHowChatMessageTime?'../../assets/img/ic_聊天时间2.svg':'../../assets//img/ic_聊天时间.svg'"
                        [ngbTooltip]="isSHowChatMessageTime?'已显示聊天信息时间':'显示每条聊天信息时间'" width="16px">
                </div>
            </div>
            <div class="paixuView divX">
                <div (click)="selectPaixuType(0)" class="center"
                    [ngClass]="{paixuItemViewLeft:paixuSelectType==0,paixuUnSelectItemViewLeft:paixuSelectType!=0}">
                    时间
                </div>
                <div (click)="selectPaixuType(1)" class="center"
                    [ngClass]="{paixuCenterItemView:paixuSelectType==1,paixuUnSelectItemView:paixuSelectType!=1}">
                    未读
                </div>
                <div (click)="selectPaixuType(2)" class="center"
                    [ngClass]="{paixuItemViewRight:paixuSelectType==2,paixuUnSelectItemViewRight:paixuSelectType!=2}">
                    会话中
                </div>
            </div>
        </div>
        <div class="listItem">
            <div style="width: 100%;margin-top: 40px;font-size: 12px;" class="center" *ngIf="list.length==0">
                暂无用户消息！
                <span style="color: #ff7f50;">
                    点击同步
                </span>
            </div>
            <div *ngFor="let item of list"
                [ngClass]="{messageMainView:listId!=item.id,messageCheckMainView:listId==item.id}"
                (click)="changeChat(item)" (contextmenu)="managerList($event,item)"
                [ngStyle]="{opacity:item.isVisible?'0.7':'1'}">
                <div class="divX centerY messageView">
                    <div class="clientImgView">
                        <div *ngIf="item.unReadNumber>0" class="unreadNumberText center">
                            <span style="transform: scale(0.83);">
                                {{item.unReadNumber}}
                            </span>
                        </div>
                        <div class="clientGender" *ngIf="item.client">
                            <img [src]="item.client.gender=='男'?'../../assets//img/ic_男.svg':'../../assets//img/ic_女.svg'"
                                width="16px">
                        </div>
                        <img [src]="item.client.avatar" class="clientImg">
                    </div>
                    <div class="messageTextView divX">
                        <div class="divY centerX">
                            <div style="font-size: 12px;" class="divX">
                                <div class="divX centerY">
                                    <div>
                                        {{item.client.name}}
                                    </div>
                                    <div *ngIf="item.chatId" class="chattingTag">
                                        会话中
                                    </div>
                                </div>
                                <img (click)="showTag($event,item.tagContent)" *ngIf="item.isTag"
                                    src="../../assets//img//ic_标记.svg" width="13px"
                                    style="margin-left: 5px;cursor: pointer;">
                                <div style="margin-left: 10px;position: absolute;right: 10px;" class="divX center">
                                    <div>
                                        {{item.lastMessageTime|dateTime}}
                                    </div>
                                </div>
                            </div>
                            <div class="messageText" *ngIf="!item.unSendText||listId==item.id">
                                {{item.lastMessageType==0?'没有任何消息':item.lastMessageType==1?item.lastMessage:item.lastMessageType==2?'[图片]':'[其他消息]'}}
                            </div>
                            <div class="messageText" *ngIf="item.unSendText&&listId!=item.id">
                                <span style="color: #ff6348;">
                                    [草稿]
                                </span>
                                <span>
                                    {{item.unSendText}}
                                </span>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="changeSizeView" (mousedown)="mouseDown($event)">

    </div>
</div>
<popup-base #selectStatus [isTransparent]="true">
    <div class="popupBack divY center" style="width: 100px;font-size: 14px;padding: 10px 0px;">
        <div (click)="selectUserStatus(0)"
            [ngClass]="{userStatusSelectView:userStatus==0,selectStatusView:userStatus!=0}" class="divX center ">
            <div class="userStatusOnlineView">
            </div>
            <div style="color: #2ed573;margin-left: 5px;">在线</div>
        </div>
        <div (click)="selectUserStatus(1)"
            [ngClass]="{userStatusSelectView:userStatus==1,selectStatusView:userStatus!=1}" class="divX center">
            <div class="userStatusFanmangView">
            </div>
            <div style="color: #ff7f50;margin-left: 5px;">繁忙</div>
        </div>
        <div [ngClass]="{userStatusSelectView:userStatus==2,selectStatusView:userStatus!=2}"
            (click)="selectUserStatus(2)" class="divX center">
            <div class="userStatusOfflineView">
            </div>
            <div style="color: #ced6e0;margin-left: 5px;">离线</div>
        </div>
    </div>
</popup-base>

<popup-base #tagContentPopup [isTransparent]="true">
    <div class="popupBack" style="padding: 15px;font-size: 12px;">
        {{tagContent}}
    </div>
</popup-base>

<popup-base #managerListPopup [isTransparent]="true">
    <div class="popupBack" style="padding: 5px 15px;font-size: 12px;width: 100px;">
        <div (click)="removeList()" class="divX centerY managerListItem">
            <img src="../../assets//img/ic_隐藏.svg">
            <div style="margin-left: 8px;">
                {{selectManagerListItem.isVisible?'恢复会话':'隐藏会话'}}
            </div>
        </div>
        <div (click)="removeListTag()" *ngIf="selectManagerListItem.isTag" class="divX centerY managerListItem">
            <img src="../../assets//img/ic_垃圾桶.svg">
            <div style="margin-left: 8px;">
                取消标记
            </div>
        </div>
        <div (click)="addListTag()" *ngIf="!selectManagerListItem.isTag" class="divX centerY managerListItem">
            <img src="../../assets//img/ic_标记.svg">
            <div style="margin-left: 8px;">
                添加标记
            </div>
        </div>
    </div>
</popup-base>

<popup-base #loginManager [isTransparent]="true">
    <div class="popupBack" style="padding: 5px 15px;font-size: 12px;width: 100px;">
        <div class="divX centerY managerListItem">
            <img src="../../assets//img/ic_垃圾桶.svg">
            <div style="margin-left: 8px;">
                更改头像
            </div>
        </div>
        <div (click)="loginOut()" class="divX centerY managerListItem">
            <img src="../../assets//img/ic_标记.svg">
            <div style="margin-left: 8px;">
                退出登录
            </div>
        </div>
    </div>
</popup-base>

<popup-base #openMiniWindow [isTransparent]='false' [isFullScreen]='true'>
    <div style="width: 100%;" class="centerX">
        <div class="popupBack"
            style="border-radius: 90px;padding:15px 20px;margin-top:50px;font-size: 14px;color: #ff7f50;">
            已切换为小窗模式，即将退出！
        </div>
    </div>
</popup-base>

<popup-inputTag #inputTagPopup (inputChange)="inputTagChange($event)">
</popup-inputTag>
<popup-setting #settingPopup>

</popup-setting>